import { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Grid,
  Modal,
  Tooltip,
  Divider,
  styled,
  Tabs,
  Tab,
  AppBar,
  useTheme,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import WindowIcon from "../../../../../../assets/images/window-icon.svg";
import LinuxIcon from "../../../../../../assets/images/linux-icon.svg";
import SucessIcon from "../../../../../../assets/images/success-table-icon.svg";
import FailIcon from "../../../../../../assets/images/fail-table-icon.svg";
import "../../BodyScriptPageStyles.scss";
import "./ReportBox.scss";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import recordsIcon from "../../../../../../assets/images/records-icon.svg";
import recordsIconDisable from "../../../../../../assets/images/records-disable-icon.svg";

import {
  DonwloadZipbox,
  GetBoxByIdAsync,
  GetEmulationBoxById,
  getPreviewLog,
  GetResultsEmulationBox,
} from "../../../../Services/ServiceScriptPage";
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  SimCardDownloadOutlined,
} from "@mui/icons-material";
import DetailsErrors from "../DetailsErrors/DetailsErrors";
import {
  ActionButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../../../../ayudas/Themes";
import { GetDate } from "../../../../../../ayudas/ScriptsFunctions";
import DetailsPreview from "../DetailsPreview/DetailsPreview";
import GaugeCounterSuccessAndFail from "../../../../../../Scripts/GaugeCounterSuccessAndFail";
import { IconsLanguages } from "../../assests/IconsLanguage";
import DetailsScript from "../DetailsScript/DetailsScript";
import { CreatePdfScript } from "../../../../../../ayudas/ExportPdf/PdfScript";
import ExporExcelMiddle from "../../../../../../ayudas/ExporExcelMiddle";
import { enqueueSnackbar } from "notistack";

const styleCheckBox = {
  color: "#c59d48",
  marginRight: "7px",
};
const styleCheckBoxUnCheck = {
  color: "#c59d4866",
  marginRight: "7px",
};

const styleCheckBoxDisable = {
  color: "#0a0818",
  marginRight: "7px",
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  backgroundColor: "#1e1c3a",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#090f1a",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#090f1a",
  },
});
export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    backgroundColor: "#332f5a",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#090f1a",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  })
);

const ButtonRecords = styled(Button)({
  padding: "10px",
  color: "#ffab2d",
  backgroundColor: "#382a2b",
  "&:hover": {
    backgroundColor: "#362a2bc4",
  },
});

export default function ReportBox(props) {
  const { BoxData, DataUser } = props;
  const [openReportBox, setOpenReportBox] = useState(false);
  const [BoxDetails, setBoxDetails] = useState(null);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [idBoxEmulation, setidBoxEmulation] = useState("");
  const [disabledAlllogs, setdisabledAlllogs] = useState(true);
  const [dateExecution, setdateExecution] = useState("");
  const [success, setSuccess] = useState(0);
  const [fail, setFail] = useState(0);
  const [enpointsCount, setenpointsCount] = useState(0);
  const [dataReport, setDataReport] = useState([]);
  const [typeDownload, setTypeDownload] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([true, true, true, true]);
  const [exportExcelData, setExportExcelData] = useState([]);
  const handleOpenReportBox = async () => {
    let PDFData = {
      Name: BoxData.row.name,
      Description: BoxData.row.description,
      NoAgents: BoxData.row.noClients,
      NoScripts: BoxData.row.noScripts,
      UpdateAt: BoxData.row.updatedAt,
      ExecuteBy: BoxData.row.executeBy,
      Scripts: [],
      TableResults: [],
      Success: 0,
      Fail: 0,
      BoxID: "",
    };
    const response = await GetBoxByIdAsync(
      DataUser.token,
      typeof BoxData?.id === "number" ? BoxData._id : BoxData.id
    );
    PDFData.Scripts = response.fk_script;
    let data = [];
    let countEndPonts = 0;
    if (response !== null) {
      countEndPonts = response.fk_client.length;
      setenpointsCount(response.fk_client.length);
      response.fk_client.forEach((endpoint) => {
        let endP = {
          id: endpoint._id,
          hostname: endpoint.workUser[0].hostname,
          platform: endpoint.osInformation[0].platform,
          online: endpoint.online,
          isolation: endpoint.isolation,
          hardwareInformation: endpoint.hardwareInformation,
          ipInformation: endpoint.IPInformation,
          isLogs: true,
          scripts: response.fk_script.map((script) => {
            return {
              id: script._id,
              name: script.name,
              language: script.language,
              platform: script.platform,
              status: "-",
              errors: "-",
              endDate: "-",
              startDate: "-",
            };
          }),
        };
        data.push(endP);
      });
    }
    PDFData.TableResults = data;
    const allExecution = await GetResultsEmulationBox(DataUser.token);

    if (allExecution) {
      for (let index = allExecution.length - 1; index >= 0; index--) {
        const element = allExecution[index];
        if (element.fk_scriptCollection === BoxData.id) {
          const getInfo = await GetEmulationBoxById(
            DataUser.token,
            element._id
          );

          setidBoxEmulation(BoxData.id);
          PDFData.BoxID = BoxData.id;
          setdisabledAlllogs(getInfo.isLogs === "Y" ? false : true);
          setdateExecution(GetDate(getInfo.updatedAt));
          if (getInfo) {
            getInfo.results.forEach((reportEnpointdata) => {
              for (let index = 0; index < data.length; index++) {
                const EndpontData = data[index];
                if (EndpontData.id === reportEnpointdata.fk_client) {
                  reportEnpointdata.scripts.forEach((script) => {
                    EndpontData.scripts.forEach((datascript) => {
                      if (script.fk_script === datascript.id) {
                        datascript.status = script.isSuccess;
                        datascript.errors = script.error;
                        datascript.isLogs = script.isLogs;
                        datascript.endDate = GetDate(script.endDate);
                        datascript.startDate = GetDate(script.startDate);
                        if (script.isLogs === "Y") {
                          EndpontData.isLogs = false;
                        }
                      }
                    });
                  });
                }
              }
            });
            setBoxDetails(data);
            let success = 0;
            let fail = 0;

            data.forEach((element) => {
              element.scripts.forEach((element2) => {
                if (element2.status === "Y") {
                  success++;
                } else {
                  fail++;
                }
              });
            });

            if (PDFData.NoScripts === 0) {
              enqueueSnackbar("The select emulation don't contain scripts", {
                variant: "info",
              });
            } else {
              setOpenReportBox(true);
              setSuccess(success);
              setFail(fail);
            }
            break;
          } else {
            enqueueSnackbar(
              "The last emulation don't generate info to show please, retry emulation",
              { variant: "info" }
            );
          }
        }
      }

      let success = 0;
      let fail = 0;

      data.forEach((element) => {
        element.scripts.forEach((element2) => {
          if (element2.status === "Y") {
            success++;
          } else {
            fail++;
          }
        });
      });
      setSuccess(success);
      setFail(fail);
      PDFData.Success = success;
      PDFData.Fail = fail;
      // console.log("Datos de Reporte", PDFData);
      setDataReport(PDFData);setTimeout(() => {        
        GaugeCounterSuccessAndFail(success, fail, success + fail, countEndPonts);
      }, 500);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      CreateExcelData();
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClose = () => {
    setOpenReportBox(false);
  };
  const DownloadZip = async (data) => {
    let dataBody = {
      box: idBoxEmulation,
      client: data.endPID,
      script: data.script.id,
    };
    await DonwloadZipbox(DataUser.token, `${data.script.name}.log`, dataBody);
  };
  const DownloadEnpointZip = async (data) => {
    let dataBody = {
      box: idBoxEmulation,
      client: data.endPID,
    };
    await DonwloadZipbox(DataUser.token, `EndpointLogs.zip`, dataBody);
  };
  const DownloadGeneral = async () => {
    let dataBody = {
      box: idBoxEmulation,
    };
    enqueueSnackbar(
      "The zip will be downloaded with the Logs available at the moment",
      { variant: "info" }
    );
    await DonwloadZipbox(DataUser.token, "AllLogs.zip", dataBody);
  };

  const handleCheckDataPdf = (position) => {
    let array = [...selectedTypes];
    let bool = array[position];
    array[position] = !bool;
    setSelectedTypes(array);
  };

  const GeneratePDF = async () => {
    let Report = dataReport;
    let Previews = [];
    const promises = Report.TableResults.map((host) => {
      return host.scripts.map((element) => {
        return getPreviewLog(
          DataUser.token,
          JSON.stringify({
            box: Report.BoxID,
            client: host.id,
            script: element.id,
            size: 250,
          })
        );
      });
    }).flat();

    const results = await Promise.all(promises);
    Previews = results;
    Report.Previews = Previews;
    CreatePdfScript(Report, selectedTypes);
  };
  const CreateExcelData = () => {
    let Report = dataReport;
    let Emulation = [];
    Report.TableResults.forEach((host) => {
      host.scripts.forEach((element) => {
        Emulation.push({
          hostname: host.hostname,
          name: element.name,
          language: element.language,
          platform: element.platform,
          startDate: element.startDate,
          endDate: element.endDate,
          status: element.status,
          errors: element.errors,
          isLogs: element.isLogs,
        });
      });
    });

    let expo = {
      data: Emulation,
      colums: [
        { header: "Hostname", key: "hostname" },
        { header: "Name", key: "name" },
        { header: "Language", key: "language" },
        { header: "Platform", key: "platform" },
        { header: "Start Date", key: "startDate" },
        { header: "End Date", key: "endDate" },
        { header: "Status", key: "status" },
        { header: "Is Log", key: "isLogs" },
        { header: "Errors", key: "errors" },
      ],
    };
    setExportExcelData(expo);
  };
  const Reload = () => {
    props.Reload();
  };
  return (
    <>
      <ButtonRecords
        onClick={handleOpenReportBox}
        disabled={BoxData.row.status === 0}
      >
        <img
          style={{ marginRight: "8px" }}
          src={BoxData.row.status === 1 ? recordsIcon : recordsIconDisable}
          className="mr-3"
          alt=""
        />
        Report
      </ButtonRecords>
      <Modal open={openReportBox} onClose={handleClose}>
        <Box
          className="ModalSettion"
          sx={{
            maxHeight: "90%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <AppBar position="static">
            <StyledTabs
              sx={{
                "& .MuiButtonBase-root": {
                  width: "100% !important",
                  margin: "auto!important",
                },
              }}
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <StyledTab
                // icon={<Summarize />}
                iconPosition="start"
                label={"Emulation Report"}
                {...a11yProps(0)}
                style={{ cursor: "default" }}
              />
              <StyledTab
                iconPosition="start"
                label="Export Report"
                {...a11yProps(1)}
              />
            </StyledTabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            style={{ backgroundColor: "#0a0818" }}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Typography variant="h5" textAlign={"center"}>
                    Emulation Name: {BoxData.row.name}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Grid container spacing={0}>
                    <Grid item md={12}>
                      <Typography
                        textAlign={"center"}
                        style={{ color: "#c59d48", margin: "12px 0px" }}
                      >
                        {"Custom Threats File Results Summary"}
                      </Typography>
                    </Grid>
                    <Grid item md={4} textAlign="center">
                      <Box className="graph endpoint">{enpointsCount}</Box>
                      <Typography color="white" variant="h6">
                        Endpoints
                      </Typography>
                    </Grid>
                    <Grid item md={4} textAlign="center">
                      <Box className="graph success">{success}</Box>
                      <Typography color="white" variant="h6">
                        Success Files
                      </Typography>
                    </Grid>
                    <Grid item md={4} textAlign="center">
                      <Box className="graph failed">{fail}</Box>
                      <Typography color="white" variant="h6">
                        Failed Files
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    align="center"
                    style={{ color: "#c59d48", margin: "12px 0px" }}
                  >
                    {"Execution Date:"}
                  </Typography>

                  <Typography align="center" style={{ margin: "0px" }}>
                    {dateExecution}
                  </Typography>
                  <Typography
                    align="center"
                    style={{ color: "#c59d48", margin: "12px 0px" }}
                  >
                    {"Download All Logs"}
                  </Typography>

                  <Typography align="center" style={{ margin: "0px" }}>
                    <SecondaryButton
                      onClick={DownloadGeneral}
                      disabled={disabledAlllogs}
                    >
                      All logs
                    </SecondaryButton>
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography textAlign={"center"} style={{ color: "#c59d48" }}>
                    Total Scripts in Emulation
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography textAlign={"center"} style={{ color: "#c59d48" }}>
                    Effectiveness
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    minHeight: "250px",
                    width: "100%",
                    display: "inline-block",
                    fill: "#FFF",
                    fontSize: "10px",
                  }}
                  id="GaugeCounterSuccessAndFail"
                ></Box>
              </Grid>
              <Divider />

              <Box className="GeneralContent">
                <Typography style={{ color: "#c59d48", margin: "0px 15px" }}>
                  Endpoints Scripts Emulation
                </Typography>
                {BoxDetails && BoxDetails.length > 0
                  ? BoxDetails.map((endP, index) => {
                      return (
                        <>
                          <Box>
                            <Accordion expanded={true}>
                              <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                style={{
                                  color: "#fff",
                                  backgroundColor: "#0a0818",
                                  textAlign: "center",
                                  cursor: "default",
                                  display: "inline-flex",
                                  width: "100%",
                                  position: "relative",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "white",
                                    display: "inline-flex",
                                    position: "absolute",
                                    left: "0",
                                  }}
                                >
                                  {endP.platform === "Windows" ? (
                                    <img
                                      src={WindowIcon}
                                      alt=""
                                      style={{
                                        marginRight: "10px",
                                        marginLeft: "7px",
                                      }}
                                    />
                                  ) : endP.platform === "GNULinux" ? (
                                    <img
                                      src={LinuxIcon}
                                      alt=""
                                      style={{
                                        marginRight: "10px",
                                        marginLeft: "7px",
                                      }}
                                    />
                                  ) : null}
                                  {endP.hostname}{" "}
                                </Typography>
                                <SecondaryButton
                                  disabled={endP.isLogs}
                                  onClick={() => {
                                    let data = {
                                      endPID: endP.id,
                                    };
                                    DownloadEnpointZip(data);
                                  }}
                                  style={{
                                    display: "inline-flex",
                                    position: "absolute",
                                    right: "0",
                                    top: "0",
                                  }}
                                >
                                  Endpoint Logs in Zip
                                </SecondaryButton>
                              </AccordionSummary>
                              <AccordionDetails
                                style={{ backgroundColor: "#0a0818" }}
                              >
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">
                                        Scripts File Emulation
                                      </TableCell>
                                      <TableCell align="center">
                                        Language
                                      </TableCell>
                                      <TableCell align="center">
                                        Script File Platform
                                      </TableCell>

                                      <TableCell align="center">
                                        Status
                                      </TableCell>
                                      <TableCell align="center">
                                        Errors
                                      </TableCell>
                                      <TableCell align="center">
                                        Actions
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {endP.scripts.map((script) => {
                                    return (
                                      <>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell align="center">
                                              <DetailsScript
                                                Choose={true}
                                                ScriptData={script}
                                                DataUser={DataUser}
                                                Reload={Reload}
                                              />
                                            </TableCell>
                                            <TableCell align="center">
                                              {
                                                IconsLanguages[
                                                  script.language === "BATHWIN"
                                                    ? "bash"
                                                    : script.language ===
                                                      "BATHLinux"
                                                    ? "shell"
                                                    : script.language ===
                                                      "phython"
                                                    ? "python"
                                                    : script.language
                                                ].chip
                                              }
                                            </TableCell>
                                            <TableCell align="center">
                                              {script.platform}
                                            </TableCell>
                                            <TableCell align="center">
                                              {script.status === "Y" ? (
                                                <img alt="" src={SucessIcon} />
                                              ) : (
                                                <img alt="" src={FailIcon} />
                                              )}
                                            </TableCell>
                                            <TableCell align="center">
                                              {script.errors === "" ? (
                                                <>{"-- --"}</>
                                              ) : (
                                                <>
                                                  <DetailsErrors
                                                    DataError={script.errors}
                                                  ></DetailsErrors>
                                                </>
                                              )}
                                            </TableCell>
                                            <TableCell align="center">
                                              <DetailsPreview
                                                DataUser={DataUser}
                                                DataScript={{
                                                  box: idBoxEmulation,
                                                  client: endP.id,
                                                  script: script.id,
                                                  size: 250,
                                                }}
                                                disabled={
                                                  script.isLogs === "Y"
                                                    ? script.status === "Y"
                                                      ? false
                                                      : true
                                                    : true
                                                }
                                              />

                                              <Tooltip title="Log File">
                                                <IconButton
                                                  disabled={
                                                    script.isLogs === "Y"
                                                      ? script.status === "Y"
                                                        ? false
                                                        : true
                                                      : true
                                                  }
                                                >
                                                  <SimCardDownloadOutlined
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      let data = {
                                                        endPID: endP.id,
                                                        script: script,
                                                      };
                                                      DownloadZip(data);
                                                    }}
                                                  ></SimCardDownloadOutlined>
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </>
                                    );
                                  })}
                                </Table>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        </>
                      );
                    })
                  : null}
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Grid container spacing={3}>
                {typeDownload ? (
                  <Grid item xs={12} textAlign={"center"}>
                    <SecondaryButton
                      onClick={() => {
                        setTypeDownload(false);
                      }}
                    >
                      Excel
                    </SecondaryButton>
                    <PrimaryButton>PDF</PrimaryButton>
                  </Grid>
                ) : (
                  <Grid item xs={12} textAlign={"center"}>
                    <PrimaryButton>Excel</PrimaryButton>
                    <SecondaryButton
                      onClick={() => {
                        setTypeDownload(true);
                      }}
                    >
                      PDF
                    </SecondaryButton>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography variant="h5" textAlign={"center"}>
                    Select Content
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor: !typeDownload ? "default" : "pointer",
                      color: !typeDownload ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeDownload) {
                        handleCheckDataPdf(0);
                      }
                    }}
                  >
                    {selectedTypes[0] ? (
                      <CheckBoxRounded
                        sx={typeDownload ? styleCheckBox : styleCheckBoxDisable}
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeDownload
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"Summary"}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor: !typeDownload ? "default" : "pointer",
                      color: !typeDownload ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeDownload) {
                        handleCheckDataPdf(1);
                      }
                    }}
                  >
                    {selectedTypes[1] ? (
                      <CheckBoxRounded
                        sx={typeDownload ? styleCheckBox : styleCheckBoxDisable}
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeDownload
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"Table of Script"}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor: !typeDownload ? "default" : "pointer",
                      color: !typeDownload ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeDownload) {
                        handleCheckDataPdf(2);
                      }
                    }}
                  >
                    {!typeDownload ? (
                      <CheckBoxRounded sx={styleCheckBox} />
                    ) : selectedTypes[2] ? (
                      <CheckBoxRounded
                        sx={typeDownload ? styleCheckBox : styleCheckBoxDisable}
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeDownload
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"Endpoints Emulation"}
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor: !typeDownload ? "default" : "pointer",
                      color: !typeDownload ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeDownload) {
                        handleCheckDataPdf(3);
                      }
                    }}
                  >
                    {selectedTypes[3] ? (
                      <CheckBoxRounded
                        sx={typeDownload ? styleCheckBox : styleCheckBoxDisable}
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeDownload
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"Previews"}
                  </Typography>{" "}
                </Grid>
                <Grid
                  item
                  xs={12}
                  justifyContent={"center"}
                  alignContent={"center"}
                  display={"flex"}
                  textAlign={"center"}
                >
                  {typeDownload ? (
                    <ActionButton onClick={GeneratePDF}>Generate</ActionButton>
                  ) : (
                    <ExporExcelMiddle
                      pageOrigin="ScriptReport"
                      externalData={exportExcelData}
                      fileName={dataReport.Name + " Custom Threat Report"}
                    />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Modal>
    </>
  );
}

ReportBox.defaultProps = {
  Information: true,
};
