import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Zoom from '@mui/material/Zoom';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import iconExecuionOndemand from "../../../../../assets/images/ondemand.png"
import iconScheduled from "../../../../../assets/images/schedule-image.svg"
import { OndemandVideo } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";


const ListItemsExecution = styled(List)({
  textAlign: "center !important",
  "& .Mui-selected": {
    backgroundColor: "#c59d48 !important",
  },
  "& .Mui-selected:hover": {
    backgroundColor: "#c59d48",
  },
  "& .Mui-selected:focus": {
    backgroundColor: "#c59d48",
  },
  "& .MuiListItemButton-root": {
    backgroundColor: "#0a0818",
    padding: "10px",
    borderRadius: "10px",
    margin: "50px",
    textAlign: "center",
    fontSize: "100px",
  },
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "#c59d48",
  },
  "& .MuiListItemButton-root:focus": {
    backgroundColor: "#c59d48",
  },
});


export default function StepTypeExecution(props) {
  const { ValueIndexExecution, AgentConnect } = props;
  const [selectedIndexExecution, setSelectedIndexExecution] =
    useState(ValueIndexExecution);
  const [agentConnect, setAgentConnect] = useState(AgentConnect);

  const [effectStatusOndemand, setEffectStatusOndemand] = useState(true);
  const [effectScheduled, setEffectScheduled] = useState(true);

  useEffect(() => {
    setSelectedIndexExecution(ValueIndexExecution);
  }, [ValueIndexExecution]);

  useEffect(() => {
    setAgentConnect(AgentConnect)
  }, [AgentConnect])

  const ValidatedAgentsOnline = () => {
    if (agentConnect.length === 0) {
      enqueueSnackbar("No agents connected for an on-demand emulation",{variant:"info"});
    }

  }
  return (
    <Box
      sx={{
        textAlign: "center !important",
        width: "100%",
        backgroundColor: "rgb(36, 41, 51)",
        border: "1px solid #1e1c3a",
        borderRadius: "8px",
        fontSize: "14px",
        padding: "3px 15px",
        height: "510px",
      }}
    >
      <Typography sx={{ marginTop:"12px", textAlign: "center" }}>
        Select type emulation
      </Typography>
      <ListItemsExecution
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0",
          borderRadius: "8px",
        }}
        component="nav"
      >
        <ListItemButton
          selected={selectedIndexExecution === 0}
          onClick={() => {
            setEffectScheduled(true);
            setEffectScheduled(false);
            setEffectStatusOndemand(false);
            setEffectStatusOndemand(true);
            ValidatedAgentsOnline();
            props.handleTypeExecution(0, "onDemand");
          }}
        >
          <OndemandVideo />
          <ListItemText primary="On Demand" />

        </ListItemButton>

        <ListItemButton
          selected={selectedIndexExecution === 1}
          onClick={() => {
            setEffectScheduled(false);
            setEffectScheduled(true);
            setEffectStatusOndemand(true);
            setEffectStatusOndemand(false);
            props.handleTypeExecution(1, "scheduled");
          }}

        >
          <CalendarTodayIcon />
          <ListItemText primary="Scheduled" />
        </ListItemButton>
      </ListItemsExecution>
      {
        selectedIndexExecution === 0 ?
          <>
            <Zoom in={effectStatusOndemand} style={{ transitionDelay: effectStatusOndemand ? '100ms' : '0ms' }}>
              <img style={{ width: "200px" }} src={iconExecuionOndemand} alt="" />

            </Zoom>
          </>
          :
          <Zoom in={effectScheduled} style={{ transitionDelay: effectScheduled ? '100ms' : '0ms' }}>

            <img src={iconScheduled} alt="" />
          </Zoom>
        //null
      }
    </Box>
  );
}
