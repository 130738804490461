import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ActionButton, FormInputTextField } from "../../../ayudas/Themes";

import detailsIcon from "../../../assets/images/eye-icon.svg";
import { UpdateExecutionEnviromentAsync } from "../../../Services/Enviroment";

export default function ModelTimeParameters(props) {
  const { DataSettings } = props;
  const [open, setOpen] = useState(false);
  const [NS, setNS] = useState(0);
  const [EPP, setEPP] = useState(0);
  const [RE, setRE] = useState(0);
  const [scriptValue, setScriptValue] = useState(10);
  const [warningActive, setWarningActive] = useState("Y");

  const handleOpen = () => {
    setNS(DataSettings[0].value);
    setEPP(DataSettings[1].value);
    setRE(DataSettings[2].value);
    setScriptValue(DataSettings[3].value);
    setWarningActive(DataSettings[4].value);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const putUpdateData = async () => {
    const enviromentData = {
      TIMENETWORKSECURITY: parseInt(NS, 10),
      TIMEEPP: parseInt(EPP, 10),
      TIMEREALEXECUTION: parseInt(RE, 10),
      TIME: parseInt(scriptValue, 10),
      INSOLATIONPROTECTION: warningActive,
      permanent: "Y",
    };
    await UpdateExecutionEnviromentAsync(enviromentData);
    props.Reload(enviromentData);
  };
  const handleWarning = (event) => {
    setWarningActive(event.target.value);
  };
  const body = (
    <div
      className="Section"
      style={{
        backgroundColor: "#090f1a",
        borderBottom: "1px solid #1e1c3a",
        width: "400px",
      }}
    >
      <Typography align="center" variant="h6">
        Update times of emulations
      </Typography>
      <Typography align="center" variant="h6">
        (Seconds)
      </Typography>
      <Box
        style={{
          width: "100%",
          backgroundColor: "rgb(36,41,51)",
          border: "1px solid #1e1c3a",
          padding: "15px",
          boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
          borderRadius: "12px",
          marginTop: "24px",
        }}
      >
        <FormInputTextField
          //disabled={ValidateVectorNS ? true:false}
          fullWidth
          style={{ margin: "7px" }}
          id="outlined-number"
          label="Time of Network"
          type="number"
          value={NS}
          onKeyDown={(e) => {
            if (e.key === ".") {
              e.preventDefault();
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            if (event.target.value !== " ") {
              setNS(event.target.value);
            }
          }}
          InputProps={{ inputProps: { min: 1, max: 300 } }}
        />
        <FormInputTextField
          fullWidth
          style={{ margin: "7px" }}
          id="outlined-number"
          label="Time of Endpoint"
          type="number"
          value={EPP}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            if (event.target.value !== " ") {
              setEPP(event.target.value);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === ".") {
              e.preventDefault();
            }
          }}
          InputProps={{ inputProps: { min: 1, max: 300 } }}
        />
        <FormInputTextField
          fullWidth
          px
          style={{ margin: "7px 7px 15px 7px" }}
          InputProps={{ inputProps: { min: 1, max: 300 } }}
          onKeyDown={(e) => {
            if (e.key === ".") {
              e.preventDefault();
            }
          }}
          id="outlined-number"
          label="Time of Execution"
          type="number"
          value={RE}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            if (event.target.value !== " ") {
              setRE(event.target.value);
            }
          }}
        />
        <Divider></Divider>
        <FormInputTextField
          fullWidth
          style={{ margin: "15px 7px" }}
          id="outlined-number"
          label="Timeout Scripts"
          type="number"
          value={scriptValue}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            if (event.target.value !== " ") {
              setScriptValue(event.target.value);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === ".") {
              e.preventDefault();
            }
          }}
          InputProps={{ inputProps: { min: 1, max: 900 } }}
        />
        <Divider></Divider>
        <Typography
          variant="caption"
          color={"lightgrey"}
          padding={"15px"}
          alignContent={"center"}
        >
          Warning notification Endpoint without isolation
          <Select
            value={warningActive}
            onChange={handleWarning}
            style={{ width: "25%", marginLeft: "15px" }}
          >
            <MenuItem value={"Y"}>Yes</MenuItem>
            <MenuItem value={"n"}>No</MenuItem>
          </Select>
        </Typography>
      </Box>
      <ActionButton
        onClick={putUpdateData}
        disabled={
          NS === "" ||
          parseInt(NS) < 1 ||
          EPP === "" ||
          parseInt(EPP) < 1 ||
          RE === "" ||
          parseInt(RE) < 1
        }
        style={{ width: "70%", marginLeft: "15%" }}
      >
        Update
      </ActionButton>
    </div>
  );

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <img src={detailsIcon} alt="" />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
