import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ModalStyle,
  checkDisable,
  checkOff,
  checkOn,
  headerStyle,
} from "../constants/styleConstants";
import { useEffect, useState } from "react";
import {
  ActionButton,
  CustomLoadingOverlay,
  FormInputTextField,
  PrimaryButton,
  SecondaryButton,
  StyledDataGrid,
} from "../../../../ayudas/Themes";
import {
  checkFileIp,
  checkGroupName,
  checkIPInGroup,
  checkISintax,
  getDefaultIps,
  handleCheckList,
  ipListIsChecked,
} from "../services/Functions";
import { BackupTwoTone, Clear, Delete } from "@mui/icons-material";
import { GridOverlay } from "@mui/x-data-grid-pro";
import { v4 as uuidv4 } from "uuid";
import { UpdateIps } from "../../../../Services/Enviroment";
import "../styles/ModelIp.scss";
import { enqueueSnackbar } from "notistack";
import TablePaginationActions from "../../../../ayudas/TablePaginations";
export default function CRUDModal(props) {
  const { sourceGroups, groupData, openModalAction, DataUser } = props; //sourceGroups all data the ips, groupData if exist to edit mode if not access to create mode, openModalAction prop to control show and hide modal
  const [defaultIPS, setDefaultIPS] = useState([]); //State to content default ips
  const [openModal, setOpenModal] = useState(false); // State to control show modal

  const [groupName, setGruopName] = useState(""); // State to save new name for the group
  const [nameError, setNameError] = useState(false); // State to show duplicite in the name
  const [newIp, setNewIp] = useState(""); // State for pass the ip to table
  const [errorIPSintax, setErrorIPSintax] = useState(false); // State showing if a mistyped ip
  const [errorIPDuplicate, setErrorIPDuplicate] = useState(false); // State showing if a repeated ip exists
  const [checkActive, setcheckActive] = useState(false); // state to Show button multi delete

  const [sourceData, setSourceData] = useState([]); // State for table data
  const [renderTableData, setRenderTableData] = useState([]); // state in charge of displaying the data in the table

  const [rowsPerPage, setRowsPerPage] = useState(10); //State to control row per page
  const [page, setPage] = useState(0); //State to control page

  const [paginationModel, setPaginationModel] = useState({
    pageSize: rowsPerPage,
    page: page,
  });

  //Funtion to change page
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    async function fetchData() {
      let defIp = await getDefaultIps(DataUser.token);
      setDefaultIPS(defIp);
      if (groupData?.ips) {
        setSourceData(groupData.ips);
        setRenderTableData(groupData.ips);
      }
      setOpenModal(openModalAction);
    }
    fetchData();
  }, [openModalAction, DataUser.token, groupData.ips]);

  //Functions control open and close modal
  const handleClose = () => {
    setErrorIPDuplicate(false);
    setErrorIPSintax(false);
    setRenderTableData([]);
    setSourceData([]);
    setPage(0);
    setNewIp("");
    setGruopName("");
    props.handleClose();
    setOpenModal(false);
  };

  // Function to update input group name
  const handleGroupName = (event) => {
    let value = event.target.value;
    setGruopName(value);
    setNameError(checkGroupName(value, sourceGroups));
  };

  // Function to update input ip value
  const HandleChangeInput = (event) => {
    let value = event.target.value;
    setNewIp(value);
    setErrorIPDuplicate(false);
    setErrorIPSintax(false);

    if (value === "") {
      setRenderTableData(sourceData);
    } else {
      let filterData = [];
      sourceData.forEach((row) => {
        let valueip = row.ip.toUpperCase().indexOf(value.toUpperCase());
        if (valueip > -1) {
          filterData.push(row);
        }
      });

      setRenderTableData(filterData);
      setPage(0);
    }
  };
  //Clean the input
  const clearDataSearch = () => {
    setRenderTableData(sourceData);
    setNewIp("");
  };

  //Function to evaluate the new ip if the checks it's correts add to renderdata and sourcedata
  const handleIp = () => {
    if (checkISintax(newIp)) {
      if (checkIPInGroup(newIp, sourceData)) {
        let array = [...sourceData];
        array.push({
          ip: newIp,
          id: uuidv4(),
          check: false,
        });
        setErrorIPDuplicate(false);
        setErrorIPSintax(false);
        setRenderTableData(array);
        setSourceData(array);
        setNewIp("");
      } else {
        setErrorIPDuplicate(true);
        enqueueSnackbar("It already exists Ip", { variant: "error" });
      }
    } else {
      setErrorIPSintax(true);
      enqueueSnackbar("Invalid ip", { variant: "error" });
    }
  };
  // Function to read a txt and get add the valid ips
  const readFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      let infoChecked = checkFileIp(text, sourceData);
      let array = [...sourceData];
      infoChecked.filterList.forEach((element) => {
        array.push({
          ip: element,
          id: uuidv4(),
          check: false,
        });
      });
      setRenderTableData(array);
      setSourceData(array);
      enqueueSnackbar(
        "Added " +
          infoChecked.filterList.length +
          " from the selected file, found " +
          infoChecked.sintaxError +
          " with syntax error, found" +
          infoChecked.existsInGroup +
          " ip already existing and found" +
          infoChecked.duplicateIPinFile +
          " ip duplicate in the file.",
        { variant: "info" }
      );
    };
    reader.readAsText(e.target.files[0]);
  };

  //Function to delete ip in sourceData
  const deleteIp = (row) => {
    let array = [...sourceData];
    let indexFound = -1;
    for (let index = 0; index < sourceData.length; index++) {
      const element = sourceData[index];
      if (element.id === row.id) {
        indexFound = index;
        break;
      }
    }
    array.splice(indexFound, 1);
    setRenderTableData(array);
    setSourceData(array);
  };

  //Function to delete multiple ips selecteds
  const deleteMultipleIp = () => {
    let newArray = [];
    for (let index = 0; index < sourceData.length; index++) {
      const element = sourceData[index];
      if (!element.check) {
        newArray.push(element);
      }
    }
    setRenderTableData(newArray);
    setSourceData(newArray);
    setcheckActive(false);
  };

  //Function to check/uncheck one ip
  const handleCheckedIp = (row) => {
    let array = [...sourceData];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (element.id === row.id) {
        element.check = !element.check;
        break;
      }
    }
    let checkedList = ipListIsChecked(array);
    setcheckActive(checkedList);
    setRenderTableData(array);
    setSourceData(array);
  };

  //Function to check/uncheck all ips
  const handleChekedAllIp = () => {
    let isChecked = !checkActive;
    let array = handleCheckList(isChecked, sourceData, defaultIPS);
    let checkedList = ipListIsChecked(array);
    setcheckActive(checkedList);
    setRenderTableData(array);
    setSourceData(array);
  };

  // Function to update ips in group using API
  const handlePolitics = () => {
    if (!groupData?.name && groupName === "") {
      enqueueSnackbar("You need to add valid name to the new group", {
        variant: "info",
      });
    } else {
      var ips = sourceData.map((item) => item.ip);
      const IpsData = {
        IPs: ips,
        name: groupData?.name ? groupData.name : groupName,
      };
      Promise.resolve(UpdateIps(IpsData));
      props.Reload();
      handleClose();
    }
  };

  //columns to show in table
  const IPColumns = [
    {
      field: "check",
      sortable: false,
      filterable: false,
      editable: false,
      align: "center",
      renderHeader: (params) => (
        <Box style={headerStyle}>
          <Box
            style={checkActive ? checkOn : checkOff}
            onClick={handleChekedAllIp}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            justifyContent: "center",
            display: "grid",
            alignItems: "center",
            height: "100%",
          }}
        >
          {defaultIPS.indexOf(params.row.ip) !== -1 ? (
            <Box style={checkDisable} />
          ) : (
            <Box
              style={params.row.check ? checkOn : checkOff}
              onClick={() => {
                handleCheckedIp(params.row);
              }}
            />
          )}
        </Box>
      ),
    },
    {
      field: "ip",
      headerName: "IP",
      flex: 1,
      sortable: true,
      filterable: false,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 0.5,
      renderCell: (params) => (
        <>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                deleteIp(params.row);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box className="Section" style={ModalStyle}>
        <Grid container spacing={0}>
          <Grid item md={12} textAlign={"center"}>
            <Typography variant="h6">
              {!groupData?.name ? "New IP Isolation Group" : "Isolation Group"}
            </Typography>
            <Divider />
          </Grid>
          <Grid item sm={12} md={5}>
            {groupData?.name ? (
              <Box>
                <Typography
                  variant="h6"
                  style={{ color: "#c59d48", display: "inline-flex" }}
                >
                  {"Group Name :  "}
                </Typography>

                <Typography variant="h5" style={{ display: "inline-flex" }}>
                  {groupData.name}
                </Typography>
              </Box>
            ) : (
              <FormInputTextField
                autoFocus
                error={nameError}
                fullWidth
                helperText={nameError ? "This name already exists" : ""}
                id="group-name"
                InputProps={{ inputProps: { color: "white" } }}
                label="Group Name"
                onChange={handleGroupName}
                value={groupName}
              />
            )}
          </Grid>

          <Grid item md={6}></Grid>
          <Grid item md={5} sx={{ marginTop: "15px" }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-search">Search</InputLabel>
              <OutlinedInput
                error={errorIPSintax || errorIPDuplicate}
                fullWidth
                id="IpsInput"
                InputProps={{ inputProps: { color: "white" } }}
                onChange={HandleChangeInput}
                placeholder="example 192.168.1.252"
                value={newIp}
                endAdornment={
                  <InputAdornment position="end">
                    {newIp === "" ? null : (
                      <IconButton
                        aria-label="delete content"
                        onClick={clearDataSearch}
                        edge="end"
                      >
                        <Clear />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={1}></Grid>
          <Grid item md={6}>
            <Box
              sx={{
                float: { xs: "none", md: "right" },
                display: { xs: "none", md: "flex" },
              }}
            >
              {checkActive ? (
                <SecondaryButton onClick={deleteMultipleIp}>
                  Delete
                </SecondaryButton>
              ) : null}

              <PrimaryButton disabled={newIp === ""} onClick={handleIp}>
                Add
              </PrimaryButton>
              <Tooltip title="Upload a file">
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".txt"
                      onChange={(e) => readFile(e)}
                    />
                    <label for="imageUpload">
                      <BackupTwoTone color="secondary" />
                    </label>
                  </div>
                </div>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item md={12}>
            <StyledDataGrid
              autoHeight
              columns={IPColumns}
              slotProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                  showFirstButton: true,
                  showLastButton: true,
                },
              }}
              slots={{
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                NoResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
              }}
              density="compact"
              disableColumnReorder={true}
              disableColumnMenu
              disableSelectionOnClick
              editMode="cell"
              experimentalFeatures={{ newEditingApi: true }}
              isCellEditable={(params) =>
                defaultIPS.indexOf(params.row.ip) === -1
              }
              onPageChange={handleChangePage}
              onPageSizeChange={(PerPage) => setRowsPerPage(PerPage)}
              page={page}
              pageSize={rowsPerPage}
              pagination
              rows={renderTableData}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[5, 10, 20]}
              onCellEditCommit={(params, event) => {
                if (checkISintax(params.value)) {
                  if (checkIPInGroup(params.value, sourceData)) {
                    let array = [...sourceData];
                    array.forEach((element, index) => {
                      if (element.id === params.id) {
                        element.ip = params.value;
                      }
                    });

                    setRenderTableData(array);
                    setSourceData(array);
                  } else {
                    if (params.reason === event.cellFocusOut) {
                      event.defaultMuiPrevented = true;
                    }

                    params.row.ip = params.formattedValue;
                    enqueueSnackbar("It already exists Ip", {
                      variant: "error",
                    });
                  }
                } else {
                  if (params.reason === event.cellFocusOut) {
                    event.defaultMuiPrevented = true;
                  }
                  params.row.ip = params.formattedValue;
                  enqueueSnackbar("Invalid ip", { variant: "error" });
                }
              }}
            />
          </Grid>
          <Grid item md={12} textAlign={"center"}>
            <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
            <ActionButton
              disabled={
                groupData?.ips
                  ? sourceData === groupData.ips
                  : sourceData.length === 0
              }
              onClick={handlePolitics}
            >
              Save
            </ActionButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
