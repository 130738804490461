import {
  Box,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CodeEditorViewer from "../../../../../../ayudas/CodeEditorViewer/CodeEditorViewer";
import {
  FormInputTextField,
  SecondaryButton,
} from "../../../../../../ayudas/Themes";
import { PrimaryButton } from "../../../../../Packages/PackageTheme";
import {
  getCodeScript,
  GetScriptByIdAsync,
  UpdateScriptById,
} from "../../../../Services/ServiceScriptPage";
import EditIcon from "../../../../../../assets/images/edit-action-icon.svg";

export default function EditScript(props) {
  const { DataUser, idScript } = props;

  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [platform, setPlatform] = useState("");
  const [platformLanguaje, setPlatformLanguaje] = useState("");
  const [executionInstruccion, setExecutionInstruccion] = useState("");
  const [executionParameters, setExecutionParameters] = useState("");
  const [executionPermissions, setExecutionPermissions] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [alias, setAlias] = useState("");

  const handleOpen = async () => {
    GetScritpById();
  };

  const GetScritpById = async () => {
    const getscriptById = await GetScriptByIdAsync(DataUser.token, idScript);
    const getConatinScriptCode = await getCodeScript(DataUser.token, idScript);
    // console.log(getscriptById);
    setCode(getConatinScriptCode?.value ? getConatinScriptCode.value : "");
    setName(getscriptById.name ? getscriptById.name : "");
    setAlias(getscriptById.scriptName ? getscriptById.scriptName : "");
    setPlatform(getscriptById?.platform ? getscriptById.platform : "");
    setExtension(
      getscriptById?.file?.extension ? getscriptById?.file?.extension : ""
    );
    // console.log(getscriptById);
    switch (getscriptById.language) {
      case "phython":
        setPlatformLanguaje("python");
        break;
      case "perl":
        setPlatformLanguaje("perl");

        break;
      case "ruby":
        setPlatformLanguaje("ruby");

        break;
      case "powershell":
        setPlatformLanguaje("powershell");

        break;
      case "BATHWIN":
        setPlatformLanguaje("bat");

        break;
      case "BATHLinux":
        setPlatformLanguaje("shell");

        break;

      default:
        setPlatformLanguaje("");
        break;
    }
    setDescription(getscriptById.description);
    setExecutionParameters(
      getscriptById?.parameters ? getscriptById.parameters : ""
    );
    setExecutionInstruccion(getscriptById?.run ? getscriptById.run : "");
    setExecutionPermissions(
      getscriptById?.preBoot ? getscriptById.preBoot : ""
    );
    setOpenModal(true);
  };

  const HandleLanguajeSelect = (value) => {
    switch (value) {
      case "python":
        setPlatformLanguaje("phython");
        setExtension(".py");
        break;
      case "perl":
        setPlatformLanguaje(value);
        setExtension(".pl");
        break;
      case "ruby":
        setPlatformLanguaje(value);
        setExtension(".rb");
        break;
      case "powershell":
        setPlatformLanguaje(value);
        setExtension(".ps1");
        break;
      case "bat":
        setPlatformLanguaje("BATHWIN");
        setExtension(".bat");
        break;
      case "shell":
        setPlatformLanguaje("BATHLinux");
        setExtension(".sh");
        break;

      default:
        break;
    }
  };

  const setHandleCode = (e) => {
    setCode(e);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const setFormText = async () => {
    var object = {
      name: name,
      extension: extension,
      alias: alias,
      code: code,
      description: description,
      platform: platform,
      platformLanguaje:
        platformLanguaje === "python" || platformLanguaje === "phython"
          ? "phython"
          : platformLanguaje === "shell"
          ? "BATHLinux"
          : platformLanguaje === "bat"
          ? "BATHWIN"
          : platformLanguaje,
      executionInstruccion: executionInstruccion,
      executionParameters: executionParameters,
      executionPermissions: executionPermissions,
    };
    const msgResult = await UpdateScriptById(DataUser.token, idScript, object);
    props.HandleReload(msgResult);
    if (msgResult) {
      setTimeout(() => {
        setOpenModal(false);
      }, 1000);
    }
  };

  return (
    <>
      <Tooltip title="Edit">
        <IconButton className="iconButtonSize">
          <img src={EditIcon} alt="" onClick={handleOpen} />
        </IconButton>
      </Tooltip>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          className="Section"
          style={{
            backgroundColor: "rgb(36,41,51)",
            border: "1px solid #090f1a",
            boxShadow: "18px 4px 35px rgb(0 0 0 / 15%)",
            borderRadius: "14px",
            padding: "5px",
            width: "50%",
            height: "95vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#090f1a",
              padding: "20px",
              borderRadius: "10px",
              width: "100%",
              height: "100%",
              overflow:"auto"
            }}
          >
            <Grid container spacing={1}>
              <Grid item md={12} textAlign="center">
                <Typography variant="h5">Edit Script</Typography>
              </Grid>
              <Grid item md={12}>
                <Card style={{ padding: "15px" }}>
                  <Typography variant="h6" textAlign={"center"}>
                    General Configuration
                  </Typography>
                  <Typography variant="body1">
                    {"File Name : " + name}
                  </Typography>
                  <FormInputTextField
                    id="input-with-icon-textfield"
                    label="Script Alias*"
                    variant="outlined"
                    style={{ width: "100%", marginTop: "10px" }}
                    value={alias}
                    onChange={(e) => {
                      setAlias(e.target.value);
                    }}
                  />
                  <CodeEditorViewer
                    handleText={setHandleCode}
                    HandleLanguajeSelect={HandleLanguajeSelect}
                    languageCode={platformLanguaje}
                    codeValue={code}
                    fromEdit={true}
                  ></CodeEditorViewer>

                  <FormInputTextField
                    style={{ marginTop: "15px" }}
                    sx={{
                      ".MuiFilledInput-root": {
                        height: "100% !important",
                        overflow: "auto",
                        backgroundColor: "rgb(21, 19, 43) !important",
                        borderRadius: "15px",
                        border: "1px solid #464366",
                      },
                      ".MuiFilledInput-root:hover": {
                        border: "1px solid #c59d48 !important",
                      },
                      ".MuiFilledInput-root:after": {
                        borderBottom: "transparent !important",
                      },
                      ".MuiFilledInput-root:before": {
                        borderBottom: "transparent !important",
                      },
                    }}
                    color="secondary"
                    label="Description*"
                    multiline
                    rows={6}
                    variant="filled"
                    fullWidth
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />

                  <FormControl
                    sx={{ marginTop: "15px", minWidth: 120 }}
                    fullWidth
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Select Platform*
                    </InputLabel>
                    <Select
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                        "& .MuiSelect-select:hover ": {
                          borderColor: "red",
                        },
                      }}
                      disabled
                      className="SelectInput"
                      label="Threat"
                      value={platform}
                      onChange={(e) => {
                        setPlatform(e.target.value);
                      }}
                    >
                      <MenuItem value="" disabled className="SelectItem">
                        Select Platform
                      </MenuItem>
                      <MenuItem className="SelectItem" value="Windows">
                        Windows
                      </MenuItem>
                      <MenuItem className="SelectItem" value="GNULinux">
                        GNU/Linux
                      </MenuItem>
                      <MenuItem className="SelectItem" value="MultiPlatform">
                        MultiPlatform
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Card>
              </Grid>

              <Grid item md={12}>
                <Card style={{ padding: "15px" }}>
                  <Typography style={{ marginTop: "15px" }} variant="h6">
                    Optional parameters configuration fields
                  </Typography>
                  {/* <FormInputTextField
                    id="input-with-icon-textfield"
                    label="Execution instruction"
                    fullWidth
                    variant="outlined"
                    value={executionInstruccion}
                    style={{ marginTop: "15px" }}
                    onChange={(e) => {
                      setExecutionInstruccion(e.target.value);
                    }}
                  />
                  <br /> */}

                  <FormInputTextField
                    id="input-with-icon-textfield"
                    label="Execution parameters"
                    fullWidth
                    variant="outlined"
                    value={executionParameters}
                    style={{ marginTop: "15px" }}
                    onChange={(e) => {
                      setExecutionParameters(e.target.value);
                    }}
                  />
                  <br />

                  {/* <FormInputTextField
                    id="input-with-icon-textfield"
                    label="Execute permissions"
                    fullWidth
                    variant="outlined"
                    value={executionPermissions}
                    style={{ marginTop: "15px" }}
                    onChange={(e) => {
                      setExecutionPermissions(e.target.value);
                    }}
                  /> */}
                </Card>
              </Grid>
              <Grid item md={12} textAlign="center">
                <PrimaryButton onClick={setFormText}>Save Script</PrimaryButton>
                <SecondaryButton onClick={handleCloseModal}>
                  Cancel
                </SecondaryButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
