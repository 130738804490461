import React from "react";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import "../../../../General.scss";
import "../../styles/User.scss";
import GeneralModalDelete from "../../../../ayudas/CommonComponents/GeneralDeleteModal";
import { enqueueSnackbar } from "notistack";
import { env } from "../../../../env";

export default function Deletemodal(props) {
  const { id } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deletelogic = async (id) => {
    var url = `${env.REACT_APP_KEYCLOAK_INSTANCE}/admin/realms/${env.REACT_APP_KEYCLOAK_REALM}/users/${id}`;
    var requestHeaders = new Headers();
    requestHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    requestHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: requestHeaders,
      redirect: "follow",
    };
    await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok || response.status === 204) {
          setOpen(false);
          enqueueSnackbar("update data successfully", {
            variant: "success",
          });
        } else {
          console.log("error", response.statusText);
        }
      })
      .catch((error) => console.log("error", error));
    handleClose();
    props.Reload();
  };

  return (
    <div style={{ display: "inline-block" }}>
      {/* <Tooltip title="Delete"> */}
      <IconButton
        className="iconButtonSize"
        style={{ color: "#66638f", display: "inline-block" }}
        onClick={handleOpen}
      >
        <Delete style={{ color: "#fff" }} />
      </IconButton>
      {/* </Tooltip> */}
      <GeneralModalDelete
        ExternalOpen={open}
        HeaderText=""
        BodyText=" Do you want to delete this user?"
        CloseAction={handleClose}
        PrimaryAction={deletelogic.bind(this, id)}
        CancelAction={handleClose}
      />
    </div>
  );
}
