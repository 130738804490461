import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SucessIcon from "../../../../assets/images/success-table-icon.svg";
import FailIcon from "../../../../assets/images/fail-table-icon.svg";
import { StyledDataGrid } from "../../../../ayudas/Themes";
import ModalAdvanceInformation from "../AdvanseInfomation/AdvanceInformation";
import DetailsmodalArtifact from "../../../Artefacts/DetailsArtifact/ArtifactDetails";

export default function TableReportToAgentsAndArtifacts(props) {
  const { DataUser, tabledata1, typeStatus } = props;
  const [data, setData] = useState(tabledata1);

  useEffect(() => {
    setData(tabledata1);
  }, [tabledata1]);

  const Columns = [
    {
      field: "client",
      headerName: "Host Name",
      sortable: false,
      filterable: false,
      flex: 1,
      align: "center",
      
    },
    {
      field: "name",
      headerName: "File",
      sortable: false,
      filterable: false,
      align: "center",
      
      
      renderCell: (params) => (
        <>
          <DetailsmodalArtifact
            DataUser={DataUser}
            artefact={params.row}
            Choose={true}
            Information={false}
          />
        </>
      ),
    },
    {
      field: "packageName",
      headerName: "Package",
      sortable: false,
      filterable: false,
      align: "center",
    
    },
    {
      field: "start",
      headerName: `Start GMT ${DataUser.GMT}`,
      flex: 1,
      sortable: false,
      filterable: false,
      align: "center",
     
    },
    {
      field: "finish",
      headerName: `Finish GMT ${DataUser.GMT}`,
      flex: 1,
      sortable: false,
      filterable: false,
      align: "center",
      
    },
    {
      field: "networdVector",
      headerName: "Network",
      flex: 0.6,
      sortable: false,
      filterable: false,
      align: "center",
      
      renderCell: (params) => (
        <>
          {params.row.fase1 === 1 && params.row.fase2 === 1 ? (
            <>
              {typeStatus ? (
                <img alt="" src={SucessIcon} />
              ) : (
                <img alt="" src={FailIcon} />
              )}
            </>
          ) : (
            <>
              {typeStatus ? (
                <img alt="" src={FailIcon} />
              ) : (
                <img alt="" src={SucessIcon} />
              )}
            </>
          )}
        </>
      ),
    },
    {
      field: "epp",
      headerName: "Endpoint",
      sortable: false,
      filterable: false,
      flex: 0.6,
     
      renderCell: (params) => (
        <Box  style={{
          alignContent: "center",
          height: "100%",
          textAlign: "center",
          width: "100%",
        }} >
          {params.row.fase3 === 1 && params.row.fase4 === 1 ? (
            <>
              {typeStatus ? (
                <img alt="" src={SucessIcon} />
              ) : (
                <img alt="" src={FailIcon} />
              )}
            </>
          ) : params.row.fase3 === 0 &&
            params.row.fase4 === 0 &&
            (params.row.vector !== "EPP" ||
              params.row.vector !== "Execution") ? (
            <Typography>N/A</Typography>
          ) : (
            <>
              {typeStatus ? (
                <img alt="" src={FailIcon} />
              ) : (
                <img alt="" src={SucessIcon} />
              )}
            </>
          )}
        </Box>
      ),
    },
    {
      field: "executionActive",
      headerName: "Execution",
      flex: 0.6,
      sortable: false,
      filterable: false,
      align: "center",
      
      renderCell: (params) => (
        <>
          {params.row.fase5 === 1 && params.row.fase6 === 1 ? (
            <>
              {typeStatus ? (
                <img alt="" src={SucessIcon} />
              ) : (
                <img alt="" src={FailIcon} />
              )}
            </>
          ) : params.row.fase5 === 0 &&
            params.row.fase6 === 0 &&
            params.row.vector !== "Execution" ? (
            <Typography>N/A</Typography>
          ) : (
            <>
              {typeStatus ? (
                <img alt="" src={FailIcon} />
              ) : (
                <img alt="" src={SucessIcon} />
              )}
            </>
          )}
        </>
      ),
    },
    {
      field: "C2",
      headerName: "C2 MITRE",
      sortable: false,
      filterable: false,
      flex:0.6,
      
      renderCell: (params) => (
        <Box  style={{
          alignContent: "center",
          height: "100%",
          textAlign: "center",
          width: "100%",
        }}>
          {
            <>
              {params.value === "Failed" ? (
                <>
                  {typeStatus ? (
                    <img alt="" src={FailIcon} />
                  ) : (
                    <img alt="" src={SucessIcon} />
                  )}
                </>
              ) : null}
              {params.value === "Needless" ? (
                <Typography>N/A</Typography>
              ) : null}
              {params.value === "OK" ? (
                <>
                  {typeStatus ? (
                    <img alt="" src={SucessIcon} />
                  ) : (
                    <img alt="" src={FailIcon} />
                  )}
                </>
              ) : null}
            </>
          }
        </Box>
      ),
    },
    {
      field: "satisfactory",
      headerName: "Emulation Status",
      flex: 0.9,
      sortable: false,
      filterable: false,
      align: "center",
     
      renderCell: (params) => (
        <>
          {params.row.satisfactory ? (
            <>
              {typeStatus ? (
                <Box className="ExecStatus" style={{ color: "#38e25d" }}>
                  <Typography>Success</Typography>
                </Box>
              ) : (
                <Box className="ExecStatus" style={{ color: "#ff4747" }}>
                  <Typography>Failed</Typography>
                </Box>
              )}
            </>
          ) : (
            <>
              {typeStatus ? (
                <Box className="ExecStatus" style={{ color: "#ff4747" }}>
                  <Typography>Failed</Typography>
                </Box>
              ) : (
                <Box className="ExecStatus" style={{ color: "#38e25d" }}>
                  <Typography>Success</Typography>
                </Box>
              )}
            </>
          )}
        </>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      filterable: false,
      type: "actions",
      renderCell: (params) => (
        <>
          <ModalAdvanceInformation
            ArtifactGlobalData={params.row}
            DataUser={DataUser}
            typeStatus={typeStatus}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <StyledDataGrid
        columns={Columns}
        rows={data}
        autoHeight={true}
        disableColumnSelector
        disableSelectionOnClick
        disableColumnMenu
      />
    </>
  );
}
