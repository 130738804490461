import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "../../../../../ayudas/Themes";
import StepNameScript from "./StepperNewScript/StepNameScript/StepNameScript";
import StepAgentScript from "./StepperNewScript/StepAgentScript/StepAgentScript";
import StepperNewEmulationScript from "./StepperNewScript/StepperNewEmulationScript";
import StepSelectScript from "./StepperNewScript/StepSelectScript/StepSelectScript";
import StepLaunchScript from "./StepperNewScript/StepLaunchScript/StepLaunchScript";
import { AddNewScriptEmulation } from "../../../Services/ServiceScriptPage";

export default function CreatedNewEmulationScript(props) {
  const { getOpenModal, DataUser, ReloadTableAgents } = props;

  const [openModal, setOpenModal] = useState(getOpenModal);
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState(null);
  const [agentSelection, setAgentSelection] = useState([]);
  const [scriptSelection, setScriptSelection] = useState([]);
  const [ReloadTableEndpoints, setReloadTableEndpoints] = useState(0);
  const [badCombination, setbadCombination] = useState(false);
  const [boolFlagCreation, setBoolFlagCreation] = useState(false);
  const [typeAgents, settypeAgents] = useState([]);
  const [typesScripts, settypesScripts] = useState([]);
  useEffect(() => {
    if (ReloadTableAgents !== 0 && ReloadTableAgents !== undefined) {
      setReloadTableEndpoints(ReloadTableAgents);
    }
  }, [ReloadTableAgents]);

  useEffect(() => {
    setOpenModal(getOpenModal);
  }, [getOpenModal]);

  const handleCloseModal = () => {
    setActiveStep(0);
    setName("");
    setDescription("");
    setAgentSelection([]);
    setScriptSelection([]);
    setOpenModal(false);
  };
  const HandleActiveStepNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const HandleActiveStepBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const GetValueNameInput = (valueInput) => {
    setName(valueInput);
  };
  const GetValueDescriptionInput = (valueInput) => {
    setDescription(valueInput);
  };
  const GetValueAgentSelection = (value) => {
    let dataagn = {
      sistem: {
        windows: 0,
        linux: 0,
      },
    };
    value.forEach((row) => {
      if (row.platform === "Windows") {
        dataagn.sistem.windows = 1;
      }
      if (row.platform === "GNULinux") {
        dataagn.sistem.linux = 1;
      }
    });
    settypeAgents(dataagn);
    setAgentSelection(value);
  };
  const GetValueScriptSelection = (value) => {
    let datascr = {
      script: {
        windows: 0,
        linux: 0,
      },
    };
    value.forEach((row) => {
      if (row.platform === "Windows") {
        datascr.script.windows = 1;
      }
      if (row.platform === "GNULinux") {
        datascr.script.linux = 1;
      }
    });
    settypesScripts(datascr);
    setScriptSelection(value);
  };

  const HandleResult = (value) => {
    handleCloseModal();
  };

  const ReviewInfo = useCallback(() => {
    let validation = false;
    if (
      (typeAgents.sistem.windows === 1 && typesScripts.script.linux === 1) ||
      (typeAgents.sistem.linux === 1 && typesScripts.script.windows === 1)
    ) {
      validation = true;
    }
    setbadCombination(validation);
  }, [typeAgents, typesScripts]); //

  useEffect(() => {
    if (activeStep === 3) {
      ReviewInfo();
    }
  }, [activeStep, ReviewInfo]);

  const GetFormRequestScriptEmulation = async () => {
    setBoolFlagCreation(true);
    var formData = {
      name: name,
      description: description,
      fk_script: scriptSelection.map((element) => {
        return element.id;
      }),
      fk_client: agentSelection.map((element) => {
        return element.id;
      }),
    };
    const msgResultResquest = await AddNewScriptEmulation(
      DataUser.token,
      formData
    );
    setBoolFlagCreation(false);
    props.HandleReload(msgResultResquest);
    HandleResult(msgResultResquest);
  };

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          className="Section"
          sx={{
            backgroundColor: "#242933",
            border: "1px solid #090f1a",
            boxShadow: "18px 4px 35px rgb(0 0 0 / 15%)",
            borderRadius: "14px",
            padding: "5px",
            width: "50%",
            minWidth:"600px",
            height: "auto",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgb(9, 15, 26)",
              padding: "20px",
              borderRadius: "10px",
              width: "100%",
              height: "100%",
            }}
          >
            <StepperNewEmulationScript ActiveStep={activeStep} />
            {activeStep === 0 ? (
              <StepNameScript
                handleNext={true}
                HandleActiveStepNext={HandleActiveStepNext}
                HandleValueInputName={GetValueNameInput}
                HandleValueInputDescription={GetValueDescriptionInput}
                valueName={name}
                valueDescription={description}
              />
            ) : activeStep === 1 ? (
              <StepAgentScript
                activeStep={activeStep}
                DataUser={DataUser}
                HandleActiveStepBack={HandleActiveStepBack}
                HandleActiveStepNext={HandleActiveStepNext}
                HandleAgentSelection={GetValueAgentSelection}
                valueSelectAgent={agentSelection}
                AgentConnect={ReloadTableEndpoints}
              />
            ) : activeStep === 2 ? (
              <StepSelectScript
                activeStep={activeStep}
                DataUser={DataUser}
                HandleActiveStepBack={HandleActiveStepBack}
                HandleActiveStepNext={HandleActiveStepNext}
                HandleScriptSelection={GetValueScriptSelection}
                valueSelectionScript={scriptSelection}
              />
            ) : (
              <StepLaunchScript
                HandleReset={handleReset}
                HandleActiveStepBack={HandleActiveStepBack}
                badCombination={badCombination}
                scriptSelection={scriptSelection}
                agentSelection={agentSelection}
                dataEmulation={{
                  name: name,
                  description: description,
                  agentSelection: agentSelection,
                  scriptSelection: scriptSelection,
                }}
              />
            )}
            <Box
              sx={{
                width: "100%",
              }}
            >
              {/* <SecondaryButton
                color="inherit"
                disabled={activeStep === 0}
                onClick={HandleActiveStepBack}
                sx={{ mr: 1 }}
              >
                Back
              </SecondaryButton> */}
              {activeStep === 3 || activeStep === 0 ? (
                <>
                  <SecondaryButton
                    color="inherit"
                    disabled={activeStep !== 3 && activeStep === 0}
                    onClick={HandleActiveStepBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </SecondaryButton>
                </>
              ) : null}

              {/* {activeStep === 3 ? (
                <Box style={{ float: "right", marginLeft: "20px" }}>
                  <PrimaryButton>Emulate</PrimaryButton>
                </Box>
              ) : null} */}
              {/* 
              {activeStep < 3 ? (
                <PrimaryButton
                  style={{ float: "right" }}
                  onClick={() => {
                    HandleActiveStepNext();
                  }}
                >
                  Next
                </PrimaryButton>
              ) : null} */}

              {activeStep === 3 ? (
                <PrimaryButton
                  disabled={boolFlagCreation}
                  style={{ float: "right" }}
                  onClick={GetFormRequestScriptEmulation}
                >
                  Save
                </PrimaryButton>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
