import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import { Typography } from "@mui/material";
import execimg from "../../../../../assets/images/execution-icon-light.svg";

import {
  FormInputTextField
} from "../../../../../ayudas/Themes";

export default function StepNameExecution(props){
  const { ValueNameExecution } = props;

  const [nameExecution, setnameExecution] = useState(ValueNameExecution);
 
  useEffect(() => {
    setnameExecution(nameExecution);
  }, [nameExecution]);

  const HandleOnChange = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== " ") {
        props.handleNameExecution(e.target.value);
        setnameExecution(e.target.value);
      } else {
        setnameExecution("");
      }
    }
  };
return(
  <Box
  style={{
    width: "100%",
    height:"510px",
    backgroundColor: "rgb(36, 41, 51)",
    border: "1px solid #1e1c3a",
    padding: "15px",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
    borderRadius: "12px",
  }}
  >
      <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
        Name emulation
      </Typography>
    <FormInputTextField
    style={{width:"600px"}}
      onChange={(e) => {
        HandleOnChange(e);
      }}
      //required
      id="outlined-basic"
      label="Name emulation*"
      variant="outlined"
      type="text"
      value={nameExecution}
    />
    <Box style={{ marginTop:"75px",textAlign: "center", fontSize: "35px" }}>
                          <img
                            style={{ width: "230px" }}
                            src={execimg}
                            alt=""
                          />
    </Box>
  </Box>    
);


}