import React, { useCallback, useEffect, useMemo, useState } from "react";
import { io } from "socket.io-client";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowFoward from "@mui/icons-material/ArrowForward";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExitToApp from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import Logo from "../../assets/Logo-dash.svg";
import AgentsIcon from "../../assets/images/lpt-icon.svg";

import ConnexionIcon from "../../assets/images/connected-icon.svg";
import DesconnexionIcon from "../../assets/images/not-connected-icon.svg";

import "./Menu.scss";

import { env2 } from "../../ayudas/Instance";
import TableArtefacts from "../Artefacts/TableArtefacts";
import Agents from "../Agents/Agents";
import PackagePage from "../Packages/Sections/PackagePage";
import Executions from "../Executions/Executions";
import ReportExecution from "../Executions/Reports/ReportExecution";

import Incursion from "../Wizard/IncursionV2";
import Dashboard from "../Dashboard/Dashboard";
import License from "../License/LicensePage";
import SetupEnviroment from "../Enviroment/Setup";
import About from "../AboutUs/AboutUs";
import { Badge, CircularProgress, Modal, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IsolationTable from "../Isolation/IsolationNetwork";
import DiagramAgentTable from "../Agents/AgentsDiagram";
import { clearStorage } from "../../ayudas/GlobalData";
import { StyledDataGrid, MaterialUISwitch } from "../../ayudas/Themes";

import { getOnlineClientsAsync } from "../../Services/Clients";
import { GetEnviromentAsymc } from "../../Services/Enviroment";
import IOCExecutions from "../IOC/IOC";

import CollapseList from "./DualMenu/DrawnerCollapse";
import ScriptPage from "../ScriptPage/MainScriptPage";
import { GetBoxByIdAsync } from "../ScriptPage/Services/ServiceScriptPage";
import { downloadFile } from "../../Services/FetchRequests";
import Support from "../Support/Support";
import { ImageArray } from "./ExportImage";
import WelcomePage from "./Welcome";
import { AppBar, Drawer, DrawerHeader } from "./Component/Constant";
import Cookies from "universal-cookie";
import Detailmodal from "../User/components/DetailUser/DetailUser";
import { enqueueSnackbar } from "notistack";
import { MoveUp } from "@mui/icons-material";
import { env } from "../../env";
import UserTable from "../User/UserList";

const viewSocketLogs = localStorage.getItem("viewSocketLogs") !== "false";
const redirect = localStorage.getItem("redirectActive") !== "false";

const version = "Version 2.6.35";
export default function MenuDrawner(props) {
  const theme = useTheme();
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  const { DataUser, closeSession } = props;
  const [socket, setSocket] = useState(null);
  const [credantial, setCredential] = useState(DataUser);

  const [Page, setPage] = useState("a");
  const [reportData, setReportData] = useState([]);
  const [open, setOpen] = useState(true);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const [OpenAgents, setOpenAgents] = useState(false);
  const [onlineAgents, setonlineAgents] = useState(0);
  const [arrayAgents, setarrayAgents] = useState([]);
  const [files, setfiles] = useState(null);
  const [textFile, settextFile] = useState(null);
  // let setLogFile = null;
  const [Reload, setReload] = useState(0);
  const [ReloadIOC, setReloadIOC] = useState(0);
  const [ReloadClientIOC, setReloadClientIOC] = useState(0);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [Icon, setIcon] = useState(1);
  const [tagExecute, setTagExecute] = useState(null);
  const [sesionId, setsesionId] = useState("");

  //const [DeveloperMode, setDeveloperMode] = useState(false);

  const postAllAgents = useCallback(async () => {
    try {
      const response = await getOnlineClientsAsync();
      if (response) {
        let array = [];
        response.forEach((element) => {
          if (element.online) {
            let OS = 0;
            OS =
              element.osInformation[0].platform.search("Windows") !== -1
                ? 1
                : OS;
            OS =
              element.osInformation[0].platform.search("GNULinux") !== -1
                ? 2
                : OS;
            OS =
              element.osInformation[0].platform.search("Mac") !== -1 ? 3 : OS;
            let ippublic = element.IPInformation[0].public.IP.split("::ffff:");
            array.push({
              id: element._id,
              online: element.online,
              isolation: element.isolation,
              hostname: element.workUser[0].hostname,
              ip: element.IPInformation.length > 0 ? ippublic : "N/A",
              private:
                element.IPInformation.length > 0
                  ? element.IPInformation[0].private.IP
                  : "N/A",
              port: element.IPInformation[0].port,
              OS: OS,
              name:
                OS === 1
                  ? element.workUser[0].usuario.split("\\")[1]
                  : element.workUser[0].usuario,
              markerOffset: 0,
              coordinates: [
                element.location.coordinates[1],
                element.location.coordinates[0],
              ],
              alias: element?.alias
                ? element.alias
                : element.hostname
                ? element.hostname
                : element.workUser[0].hostname,
            });
          }
        });
        setonlineAgents(array.length);
        setarrayAgents(array);
      } else {
        setonlineAgents(0);
        setarrayAgents([]);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (Page === "a" && DataUser?.user.result) {
      async function GetData() {
        setIcon(parseInt(DataUser.user.result?.urlIma || 0, 10) + 1);
        let day = new Date();
        let GMT = day.toString().split("GMT")[1].split(" ")[0];
        DataUser.GMT = GMT;
        const response = await GetEnviromentAsymc();
        if (response) {
          response.forEach((element) => {
            if (element.name === "EXECUTIONS") {
              element.settings.forEach((setting) => {
                if (setting.variable === "TIMENETWORKSECURITY") {
                  DataUser.TIMENETWORKSECURITY = setting.value;
                }
                if (setting.variable === "TIMEEPP") {
                  DataUser.TIMEEPP = setting.value;
                }
                if (setting.variable === "TIMEREALEXECUTION") {
                  DataUser.TIMEREALEXECUTION = setting.value;
                }
                if (setting.variable === "INSOLATIONPROTECTION") {
                  DataUser.INSOLATIONPROTECTION = setting.value;
                }
              });
            }
          });
          localStorage.setItem("UsrDto", JSON.stringify(DataUser));
          setCredential(DataUser);
          let validationPage = cookies.get("CurrentPageDataPagination");
          if (validationPage) {
            if (
              validationPage === 1 &&
              env.REACT_APP_INTERNAL_CRUD_USER !== "true"
            ) {
              validationPage = 0;
            }
          } else {
            validationPage = 0;
          }

          setPage(validationPage);
        }
      }

      setSocket(
        io(env2.socketio, {
          withCredentials: true,
          auth: {
            token: localStorage.getItem("token"),
          },
          query: { "user-agent": navigator.userAgent },

          extraHeaders: {
            "I-AM": "Web",
          },
        })
      );

      GetData();

      postAllAgents();
    }
  }, [Page, cookies, DataUser, postAllAgents]);

  const Logout = () => {
    clearStorage();
    closeSession();
  };

  useEffect(() => {
    postAllAgents();
  }, [Reload, postAllAgents]);

  useEffect(() => {
    const consolesLogs = viewSocketLogs;

    if (!socket) return;
    var sesID = "";
    socket.on("connect", () => {
      let Request = {
        UserHash: localStorage.getItem("DI"),
        Type: "web",
      };

      socket.emit("R_CONN_REQ", { Request });
    });
    socket.on("connect_error", (err) => {
      console.log(err);
    });
    socket.on("disconnect", (msg) => {});
    socket.on("R_CONN_ACK", (msg) => {
      if (consolesLogs) {
        console.log("newSessionID", msg.Data.SesionId, "R_CONN_ACK", msg);
      }
      if ("OK" === msg.Status) {
        setsesionId(msg.Data.SesionId);
        sesID = msg.Data.SesionId;
        return false;
      }
    });
    socket.on("W_NEW_CONN", (msg) => {
      if (consolesLogs) {
        console.log("SessionID", sesID, "W_NEW_CONN ", msg);
      }

      setReload((x) => {
        return x + 1;
      });
      setReloadClientIOC((x) => {
        return x + 1;
      });
      return false;
    });
    socket.on("W_NEW_DESCONN", (msg) => {
      if (consolesLogs) console.log("SessionID", sesID, "W_NEW_DESCONN", msg);
      setReload((x) => {
        return x + 1;
      });
      setReloadClientIOC((x) => {
        return x + 1;
      });
      return false;
    });
    // socket.on("W_REPORT", (AckStatus) => {
    //   if (consolesLogs) console.log("SessionID", sesID, "W_REPORT", AckStatus);

    //   setTagStatus(AckStatus);
    // });
    socket.on("W_COMM_EXECUTE_ACK", (AckStatus) => {
      if (consolesLogs)
        console.log("SessionID", sesID, "W_COMM_EXECUTE_ACK", AckStatus);

      setTagExecute(AckStatus);
      // if(AckStatus.Status=="NG"){
      //   enqueueSnackbarError(AckStatus.MessageError);
      // }
    });
    socket.on("W_SCRIPT_BOX_ACK", (AckStatus) => {
      if (consolesLogs)
        console.log("SessionID", sesID, "W_SCRIPT_BOX_ACK", AckStatus);

      //  console.log(AckStatus);
    });
    socket.on("W_COMM_RESET_ACK", (AckStatus) => {
      if (consolesLogs)
        console.log("SessionID", sesID, "W_COMM_RESET_ACK", AckStatus);

      // console.log(AckStatus);
    });
    socket.on("M_PUSH_ACK", (AckStatus) => {
      if (consolesLogs)
        console.log("SessionID", sesID, "M_PUSH_ACK", AckStatus);

      // console.log("status",AckStatus);
    });
    socket.on("W_SAVE_SCRIPT_BOX_ACK", async (AckStatus) => {
      if (consolesLogs)
        console.log("SessionID", sesID, "W_SAVE_SCRIPT_BOX_ACK", AckStatus);

      if (AckStatus?.Data && AckStatus.Code === "SOCKET.ExeFinished") {
        const response = await GetBoxByIdAsync(
          localStorage.getItem("token"),
          AckStatus.Data
        );
        if (response?.name) {
          enqueueSnackbar(
            `Emulation Finished: ${response.name}, preparing logs`,
            { variant: "info" }
          );
        }
      }
    });

    socket.on("W_COMM_FILELIST_ACK", (msg) => {
      if (consolesLogs)
        console.log("SessionID", sesID, "W_COMM_FILELIST_ACK", msg);

      if ("OK" === msg.status) {
        setfiles(msg.data);

        return false;
      }

      setfiles(null);
    });
    socket.on("W_FILE_ACK", (msg) => {
      if (consolesLogs) console.log("SessionID", sesID, "W_FILE_ACK", msg);

      if ("OK" === msg.status) {
        settextFile(msg.data);

        return false;
      }
      settextFile(null);
    });

    socket.on("W_LOG_ACK", (msg) => {
      if (consolesLogs) console.log("SessionID", sesID, "W_LOG_ACK", msg);

      if ("OK" === msg.status) {
        let url = env2.api + msg.data.replace(".", "");
        downloadFile(localStorage.getItem("token"), url, "Insider_Logs.zip");
      }
      if ("NG" === msg.status) {
        enqueueSnackbar(msg.messageError, { variant: "info" });
      }
    });
    // socket.on("W_LOGLIST_ACK", (msg) => {
    //   //console.log(msg);
    //   if ("OK" === msg.status) {
    //     setLogFile=msg.data;
    //     console.log
    //     return false;
    //   }
    //   setLogFile=null;
    // });
    socket.on("W_ISOLATION_ACK", (msg) => {
      if (consolesLogs) console.log("SessionID", sesID, "W_ISOLATION_ACK", msg);

      setReload((x) => {
        return x + 1;
      });
      return false;
    });
    socket.on("W_IOC_ACK", (msg) => {
      if (consolesLogs) console.log("SessionID", sesID, "W_IOC_ACK", msg);

      if (msg.Code === "SOCKET.OfflineCli") {
        enqueueSnackbar("Endpoint is offline", { variant: "error" });
      }
      setReloadIOC((x) => {
        return x + 1;
      });
      return false;
    });
    socket.on("W_USER_CLOSED", (msg) => {
      if (consolesLogs) console.log("SessionID", sesID, "W_USER_CLOSED", msg);

      enqueueSnackbar("Your session is closed", { variant: "info" });
    });
  }, [socket]);

  const Execute = (ID) => {
    const Request = {
      Execution: ID,
    };
    socket.emit("W_COMM_EXECUTE_REQ", { Request });
    if (viewSocketLogs)
      console.log("SessionID", sesionId, "W_COMM_EXECUTE_REQ", Request);
  };
  const IsolationRequest = (Request) => {
    socket.emit("W_COMM_ISOLATION_REQ", { Request });
    if (viewSocketLogs)
      console.log("SessionID", sesionId, "W_COMM_ISOLATION_REQ", Request);
  };
  const AskFiles = (id) => {
    let Request = { Client: id };
    socket.emit("W_COMM_FILELIST_REQ", { Request });
    if (viewSocketLogs)
      console.log("SessionID", sesionId, "W_COMM_FILELIST_REQ", Request);
  };
  const GetFile = (Request) => {
    socket.emit("W_COMM_FILE_REQ", { Request });
    if (viewSocketLogs)
      console.log("SessionID", sesionId, "W_COMM_FILE_REQ", Request);
  };
  const AskLogFiles = (Request) => {
    socket.emit("W_COMM_LOG_REQ", { Request });
    if (viewSocketLogs)
      console.log("SessionID", sesionId, "W_COMM_LOG_REQ", Request);
  };

  const ExecuteIOC = (Request) => {
    socket.emit("W_COMM_IOC_REQ", { Request: Request });
    if (viewSocketLogs)
      console.log("SessionID", sesionId, "W_COMM_IOC_REQ", Request);
  };
  const ResetInsider = (id) => {
    let Request = { Client: id };
    socket.emit("W_COMM_RESET_REQ", { Request });
    if (viewSocketLogs)
      console.log("SessionID", sesionId, "W_COMM_RESET_REQ", Request);
  };

  const ExecuteScriptEmulation = (body) => {
    socket.emit("W_COMM_SCRIPT_BOX_REQ", { Request: { Box: body } });
    if (viewSocketLogs)
      console.log("SessionID", sesionId, "W_COMM_SCRIPT_BOX_REQ", {
        Request: { Box: body },
      });
  };

  const HandlePage = (page) => {
    if (page === 9) {
      settextFile(null);
      setfiles(null);
    }
    setPage(page);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const Report = (ReportData) => {
    setReportData(ReportData);
    setPage(66);
  };

  const isolationChange = (_id) => {
    let array = [...arrayAgents];
    for (let index = 0; index < array.length; index++) {
      if (array[index].id === _id) {
        let Client = _id;
        let Isolation = array[index].isolation ? 0 : 1;
        array[index].isolation = "Pr";
        setarrayAgents(array);

        const Request = { Client, Isolation };
        IsolationRequest(Request);
        break;
      }
    }
  };

  const pages = {
    a: <WelcomePage />,
    0: (
      <Dashboard
        SelectedPage={Page}
        Logout={Logout}
        DataUser={credantial}
        AgentConnect={arrayAgents}
        Redirect={() => {
          setPage(4);
        }}
      />
    ),
    1: <UserTable DataUser={credantial} Logout={Logout} UpdateId={() => {}} />,
    2: (
      <TableArtefacts
        DataUser={credantial}
        SelectedPage={Page}
        Logout={Logout}
      />
    ),
    4: (
      <Agents
        SelectedPage={Page}
        DataUser={credantial}
        Isolation={IsolationRequest}
        AskFiles={AskFiles}
        Files={files}
        GetFile={GetFile}
        FileRecive={textFile}
        Logout={Logout}
        ResetInsider={ResetInsider}
        Reload={Reload}
        RedirectPacks={() => {
          setPage(5);
        }}
        RedirectExecution={() => {
          setPage(6);
        }}
      />
    ),

    5: (
      <PackagePage DataUser={credantial} Logout={Logout} SelectedPage={Page} />
    ),
    6: (
      <Executions
        Report={Report}
        DataUser={credantial}
        Execute={Execute}
        tagExecute={tagExecute}
        Logout={Logout}
        AgentConnect={arrayAgents}
        SelectedPage={Page}
      />
    ),
    66: (
      <ReportExecution
        ReportDataProps={reportData}
        Back={() => {
          setPage(6);
        }}
        Logout={Logout}
        DataUser={credantial}
      />
    ),

    777: (
      <Incursion
        Execute={Execute}
        Back={() => {
          setPage(6);
        }}
        Logout={Logout}
        DataUser={credantial}
        Reload={Reload}
      />
    ),
    7: (
      <SetupEnviroment
        DataUser={credantial}
        Logout={Logout}
        ReloadEnviroments={async (ReloadEnviroments) => {
          const response = await GetEnviromentAsymc();
          if (response) {
            response.forEach((element) => {
              if (element.name === "EXECUTIONS") {
                element.settings.forEach((setting) => {
                  if (setting.variable === "TIMENETWORKSECURITY") {
                    DataUser.TIMENETWORKSECURITY = setting.value;
                  }
                  if (setting.variable === "TIMEEPP") {
                    DataUser.TIMEEPP = setting.value;
                  }
                  if (setting.variable === "TIMEREALEXECUTION") {
                    DataUser.TIMEREALEXECUTION = setting.value;
                  }
                  if (setting.variable === "INSOLATIONPROTECTION") {
                    DataUser.INSOLATIONPROTECTION = setting.value;
                  }
                });
              }
            });
            localStorage.setItem("UsrDto", JSON.stringify(DataUser));
            setCredential(DataUser);
          }
        }}
      />
    ),
    9: (
      <IsolationTable
        Logout={Logout}
        DataUser={credantial}
        Isolation={IsolationRequest}
        AskFiles={AskFiles}
        Files={files}
        GetFile={GetFile}
        FileRecive={textFile}
        CleanText={() => {
          settextFile(null);
        }}
        Reload={Reload}
      />
    ),
    12: (
      <Support
        handleRedirect={() => {
          setPage(10);
        }}
      />
    ),
    13: <License />,
    14: (
      <DiagramAgentTable
        DataUser={credantial}
        Logout={Logout}
        AskLogFiles={AskLogFiles}
        Reload={Reload}
      />
    ),
    15: <About />,
    16: (
      <IOCExecutions
        DataUser={credantial}
        Logout={Logout}
        Reload={ReloadIOC}
        ReloadClientIOC={ReloadClientIOC}
        Execute={ExecuteIOC}
        ResetInsider={ResetInsider}
      />
    ),

    17: (
      <ScriptPage
        DataUser={credantial}
        Logout={Logout}
        ReloadAgents={Reload}
        Execute={ExecuteScriptEmulation}
        AgentConnect={arrayAgents}
      />
    ),
  };

  const handleModalAgents = () => {
    setOpenAgents(!OpenAgents);
  };

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);

  const isMenuAppOpen = Boolean(anchorElProfile);

  const handleProfileMenuOpen = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorElProfile(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const goUrl = (url) => {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    a.click();
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorElProfile}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuAppOpen}
      onClose={handleMenuClose}
      className="menu-v3-class"
    >
      {env.REACT_APP_INTERNAL_CRUD_USER === "true" && (
        <MenuItem>
          <Detailmodal
            rol={DataUser.user.result.rol}
            expandButton={true}
            User={DataUser.user.result}
            DataUser={DataUser}
            Reload={(data) => {
              DataUser.user.result.lastName = data.lastName;
              DataUser.user.result.firtsName = data.firstName;
              setIcon(parseInt(data.attributes.avatar, 10) + 1);
            }}
          />
        </MenuItem>
      )}
      <MenuItem onClick={Logout}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <ExitToApp />
        </IconButton>
        <p> Exit</p>
      </MenuItem>
      {redirect && (
        <MenuItem
          onClick={() => {
            goUrl(env.REACT_APP_REDIRECT_URL);
          }}
        >
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <MoveUp />
          </IconButton>
          <p> Go to Epiphany page</p>
        </MenuItem>
      )}
      <MenuItem disabled>
        <p>{version}</p>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        className="menu-v3-class"
      >
        {ImageArray[Icon]}
        <Typography
          style={{
            fontSize: "15px",
            color: "#ffffff",
            fontWeight: "600",
            display: "block",
          }}
          align="center"
        >
          {DataUser.user.result.email}
        </Typography>
        <Typography
          style={{
            width: "100%",
            fontSize: "14px",
            color: "#66638f",
            display: "block",
          }}
          align="center"
        >
          {DataUser.user.result.rol}
        </Typography>
        {env.REACT_APP_INTERNAL_CRUD_USER === "true" && (
          <MenuItem>
            <Detailmodal
              rol={DataUser.user.result.rol}
              expandButton={true}
              User={DataUser.user.result}
              DataUser={DataUser}
              Reload={(data) => {
                DataUser.user.result.lastName = data.lastName;
                DataUser.user.result.firtsName = data.firstName;
                setIcon(parseInt(data.attributes.avatar, 10) + 1);
              }}
            />
          </MenuItem>
        )}
        {onlineAgents > 0 ? (
          <MenuItem onClick={handleModalAgents}>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Tooltip title="Online Endpoints">
                <Badge badgeContent={onlineAgents} color="error">
                  <img src={AgentsIcon} alt="" />
                </Badge>
              </Tooltip>
            </IconButton>
            <p>Endpoints Connected</p>
          </MenuItem>
        ) : null}
        {redirect && (
          <MenuItem
            onClick={() => {
              goUrl(env.REACT_APP_REDIRECT_URL);
            }}
          >
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <MoveUp />
            </IconButton>
            <p> Go to Epiphany page</p>
          </MenuItem>
        )}
        <MenuItem onClick={Logout}>
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <ExitToApp />
          </IconButton>
          <p> Exit</p>
        </MenuItem>{" "}
        <MenuItem disabled>
          <p> {version}</p>
        </MenuItem>
      </Menu>
    </Box>
  );
  const columns = [
    {
      field: "online",
      headerName: "|",
      flex: 0.1,
      align: "center",
      type: "boolean",
      renderCell: (params) => (
        <>
          {params.value ? (
            <Box>
              <Tooltip title="Connected">
                <img
                  src={ConnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <Tooltip title="Disconnected">
                <img
                  src={DesconnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "hostname",
      headerName: "Endpoint",
      flex: 1.5,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.row.online ? "green" : "rgba(255,255,255,0.65)",

            textAlign: "left",
            width: "100%",
            fontWeight: "600",
            alignContent: "center",
            height: "100%",
          }}
        >
          {params.row?.alias ? params.row.alias : params.row.hostname}
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "User name",
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontWeight: "600",
            alignContent: "center",
            height: "100%",
            color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
          }}
        >
          {params.value}
        </Typography>
      ),
    },

    {
      field: "isolation",
      headerName: "Isolation",
      flex: 0.5,
      align: "center",
      type: "actions",
      renderCell: (params) => (
        <>
          {params.value === "Pr" ? (
            <CircularProgress />
          ) : (
            <Tooltip
              title={params.value ? "Network Isolated" : "Network Connected"}
            >
              <MaterialUISwitch
                sx={{ m: 1 }}
                disabled={
                  DataUser.user.result.rol !== "admin" ||
                  !params.row.online ||
                  params.row.OS === 2
                }
                checked={params.value}
                onClick={isolationChange.bind(this, params.row.id)}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      {DataUser ? (
        <Box sx={{ display: "flex" }}>
          <Modal open={OpenAgents} onClose={handleModalAgents}>
            <Box className="Section" style={{ width: "50%" }}>
              <Box
                style={{
                  width: "100%",
                  backgroundColor: "#242933",
                  border: "1px solid #1e1c3a",
                  padding: "15px",
                  boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
                  borderRadius: "12px",
                }}
              >
                <Typography variant="h6" align="center">
                  Endpoints Connected
                </Typography>
                <StyledDataGrid
                  rows={arrayAgents}
                  columns={columns}
                  density="comfortable"
                  autoHeight
                  disableColumnMenu={true}
                />
              </Box>
            </Box>
          </Modal>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{
              padding: "7px 15px",
              backgroundColor: "#090f1a",
              boxShadow: "0px 25px 25px rgb(0 0 0 / 25%)",
            }}
          >
            <Toolbar>
              <Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  style={{ marginLeft: "-40px" }}
                >
                  <img
                    src={Logo}
                    alt=""
                    style={{
                      width: "180px",
                      display: !open ? "inline-block" : "none",
                    }}
                  />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginLeft: "-10px",
                    ...(open && { display: "none" }),
                  }}
                  style={{ display: !open ? "inline-block" : "none" }}
                >
                  <ArrowFoward fontSize="large" style={{ fill: "#c59d48" }} />
                </IconButton>
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {onlineAgents > 0 ? (
                  <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit"
                    onClick={handleModalAgents}
                  >
                    <Tooltip title="Online Endpoints">
                      <Badge badgeContent={onlineAgents} color="error">
                        <img src={AgentsIcon} alt="" />
                      </Badge>
                    </Tooltip>
                  </IconButton>
                ) : null}

                <IconButton
                  size="middle"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => {
                    // setDeveloperMode(!DeveloperMode);
                  }}
                >
                  {ImageArray[Icon]}
                </IconButton>
                <Box
                  style={{
                    display: "inline-block",
                    minWidth: "200px",
                    marginTop: "15px",
                  }}
                >
                  <Typography
                    component="p"
                    style={{
                      fontSize: "15px",
                      color: "#ffffff",
                      fontWeight: "600",
                      margin: " 0 0 0 10px",
                    }}
                  >
                    {DataUser.user.result.email}
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "capitalize",
                      fontSize: "14px",
                      color: "#66638f",
                      margin: "0 0 0 10px",
                      display: "block",
                    }}
                  >
                    {DataUser.user.result.rol}
                  </Typography>
                </Box>

                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <Badge badgeContent={onlineAgents} color="error">
                    <MoreIcon />
                  </Badge>
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
          <Drawer
            variant="permanent"
            open={open}
            style={{ overflow: "hidden", border: "0 transparent" }}
          >
            <DrawerHeader>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
              >
                <img
                  src={Logo}
                  alt=""
                  style={{ width: "180px", marginTop: "7px" }}
                />
              </IconButton>{" "}
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <MenuIcon fontSize="large" style={{ fill: "#c59d48" }} />
                ) : (
                  <MenuIcon fontSize="large" style={{ fill: "#c59d48" }} />
                )}
              </IconButton>
            </DrawerHeader>
            <CollapseList
              pageExternal={Page}
              ExternalOpen={open}
              Logout={Logout}
              DataUser={DataUser}
              pageSeleted={HandlePage}
            />
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            {pages[Page]}
          </Box>
        </Box>
      ) : (
        <>Cargando datos</>
      )}
    </>
  );
}
