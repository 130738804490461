import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Card,
  CardActionArea,
  CardContent,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormInputTextField } from "../../../../../ayudas/Themes";
import { includes } from "lodash";
import { PadNumbers } from "../../../../../ayudas/GlobalData";
import { Coronavirus, DesktopWindows, Webhook } from "@mui/icons-material";
import "./StepVectorExecution.scss";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function StepVectorExecution(props) {
  const {
    DataUser,
    ValueVectorIndex,
    ValueNameExecution,
    ValueIndexExecution,
    ValueCheckedAuto,
    valuesVisibles,
    ValueTimeRe,
    ValueTimeEPP,
    ValueTimeNS,
  } = props;
  const [valueVectorIndex, setValueVectorIndex] = useState(ValueVectorIndex);
  const [nameExecution, setnameExecution] = useState(ValueNameExecution);

  const [value, setValue] = useState(
    dayjs(moment().add(10, "minutes").format("YYYY-MM-DDTHH:mm"))
  );

  const [checked, setChecked] = useState(ValueCheckedAuto);

  const [objectRe, setobjectRe] = useState(ValueTimeRe);
  const [objectEPP, setobjectEPP] = useState(ValueTimeEPP);
  const [objectNS, setobjectNS] = useState(ValueTimeNS);

  const [visiblesValues, setVisiblesValues] = useState(valuesVisibles);
  const [boolNS, setboolNS] = useState(false);
  const [boolEPP, setboolEPP] = useState(false);
  const [boolRE, setboolRE] = useState(false);
  useEffect(() => {
    setValueVectorIndex(ValueVectorIndex);
  }, [ValueVectorIndex]);

  useEffect(() => {
    setnameExecution(nameExecution);
  }, [nameExecution]);

  useEffect(() => {
    if (visiblesValues === "1,0,0") {
      setboolNS(false);
      setboolEPP(true);
      setboolRE(true);
    } else if (visiblesValues === "0,1,0") {
      setboolNS(false);
      setboolEPP(false);
      setboolRE(true);
    } else if (visiblesValues === "0,0,1") {
      setboolNS(false);
      setboolEPP(false);
      setboolRE(false);
    }
  }, [visiblesValues]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.handleChangeAuto(event.target.checked);
    if (event.target.checked === true) {
      setobjectRe(DataUser.TIMEREALEXECUTION);
      setobjectEPP(DataUser.TIMEEPP);
      setobjectNS(DataUser.TIMENETWORKSECURITY);
    }
  };

  return (
    <Box
      style={{ height: "auto" }}
      sx={{
        "& .MuiTextField-root": { m: 1 },
      }}
      autoComplete="off"
    >
      <Grid
        container
        spacing={0}
        style={{
          backgroundColor: "rgb(36, 41, 51)",
          border: "1px solid #1e1c3a",
          borderRadius: "8px",
          fontSize: "14px",
          margin: "auto",
          minHeight: "510px",
        }}
      >
        <Grid item md={12}>
          <FormGroup style={{ float: "right" }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#c59d48",
                    "&.Mui-checked": {
                      color: "#c59d48",
                    },
                  }}
                  defaultChecked
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label="Default time"
            />
          </FormGroup>
        </Grid>
        <Grid item md={12}>
          {!checked ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormInputTextField
                    style={{ width: "50%" }}
                    disabled={boolNS}
                    id="outlined-number"
                    label="Time of Network"
                    type="number"
                    onKeyDown={(e) => {
                      if (!includes(PadNumbers, e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={objectNS}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      if (event.target.value !== ".") {
                        if (event.target.value <= 300) {
                          props.handleValueTimeNS(event.target.value);
                          setobjectNS(event.target.value);
                        }
                      }
                    }}
                    InputProps={{ inputProps: { min: 1, max: 300 } }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInputTextField
                    style={{ width: "50%" }}
                    disabled={boolEPP}
                    id="outlined-number"
                    label="Time of Endpoint"
                    type="number"
                    value={objectEPP}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onKeyDown={(e) => {
                      if (!includes(PadNumbers, e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(event) => {
                      if (event.target.value !== ".") {
                        if (event.target.value <= 300) {
                          props.handleValueTimeEPP(event.target.value);
                          setobjectEPP(event.target.value);
                        }
                      }
                    }}
                    InputProps={{ inputProps: { min: 1, max: 300 } }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormInputTextField
                    style={{ width: "50%" }}
                    disabled={boolRE}
                    InputProps={{ inputProps: { min: 1, max: 300 } }}
                    id="outlined-number"
                    label="Time of Execution"
                    type="number"
                    onKeyDown={(e) => {
                      if (!includes(PadNumbers, e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={objectRe}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      if (event.target.value !== ".") {
                        if (event.target.value <= 300) {
                          props.handleValueTimeRe(event.target.value);
                          setobjectRe(event.target.value);
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid item md={4}>
          <Card
            sx={{
              margin: "auto",
              maxWidth: 345,
              minHeight: 350,
              backgroundColor:
                valueVectorIndex === 0 ? "#c59d48" : "transparent",
              ".MuiCardContent-root": {
                paddingTop: "0px !important",
              },
            }}
          >
            <CardActionArea
              onClick={() => {
                setVisiblesValues("1,0,0");
                props.handleVector(0, "NetworkSecurity");
                props.handlevisiblesValues("1,0,0");
                setValueVectorIndex(0);
              }}
            >
              <Box className="ContainerIconExecution">
                <Tooltip title="Network">
                  <Coronavirus className="IconExecution" />
                </Tooltip>
              </Box>

              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Network
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Challenge the network vector by sending the artifacts and
                  samples from the cloud instance. With this emulation, you can
                  validate if the controls, appliances, policies, and other
                  cyber-security methods can detect, stop, prevent or even
                  mitigate the download of malicious objects.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card
            sx={{
              margin: "auto",
              maxWidth: 345,
              minHeight: 350,
              backgroundColor:
                valueVectorIndex === 1 ? "#c59d48" : "transparent",
              ".MuiCardContent-root": {
                paddingTop: "0px !important",
              },
            }}
          >
            <CardActionArea
              onClick={() => {
                setVisiblesValues("0,1,0");

                props.handleVector(1, "EPP");
                props.handlevisiblesValues("0,1,0");

                setValueVectorIndex(1);
              }}
            >
              <Box className="ContainerIconExecution">
                <Tooltip title="Endpoint">
                  <DesktopWindows className="IconExecution" />
                </Tooltip>
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Endpoint
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Selecting this type of emulation adds the previous vector as
                  default. Within the time-frame configured in the emulation,
                  the software will evaluate the solutions and policies
                  configured on the endpoint. In an automated process, the
                  samples are moved within folders to evaluate the static
                  analysis of the solutions.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card
            sx={{
              margin: "auto",
              maxWidth: 345,
              minHeight: 350,
              backgroundColor:
                valueVectorIndex === 2 ? "#c59d48" : "transparent",
              ".MuiCardContent-root": {
                paddingTop: "0px !important",
              },
            }}
          >
            <CardActionArea
              onClick={() => {
                setVisiblesValues("0,0,1");

                props.handleVector(2, "RealExecution");
                props.handlevisiblesValues("0,0,1");

                setValueVectorIndex(2);
              }}
            >
              <Box className="ContainerIconExecution">
                <Tooltip title="Execution">
                  <Webhook className="IconExecution" />
                </Tooltip>
              </Box>

              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Execution
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Selecting this type of emulation adds the previous two vectors
                  as default. The software installed on the endpoint will
                  execute the samples that remain after network and endpoint
                  evaluation. The software will evaluate if the processes of the
                  artifacts/samples are active in the time configured on the
                  emulation.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item md={12}>
          {ValueIndexExecution === 1 ? (
            <Box style={{ textAlign: "center", marginTop: "20px" }}>
              <Typography sx={{ mb: 1, textAlign: "center" }}>
                Select the date of the emulation
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DateTimePicker
                  value={moment()}
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      setValue(newValue);
                      props.handleDate(newValue.toISOString());
                    } else {
                      props.handleDate(null);
                    }
                  }}
                  min={moment().add(10, "minutes")}
                /> */}
                <DateTimePicker
                  label="Controlled picker"
                  value={value}
                  onChange={(newValue) => {
                    props.handleDate(newValue.toISOString());
                    setValue(newValue);
                  }}
                />
              </LocalizationProvider>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}
