import { Button, Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import CodeEditorViewer from "../../../../../ayudas/CodeEditorViewer/CodeEditorViewer";
import FileUpload from "../../../../../ayudas/CommonComponents/FileUpload";
import {
  FormInputTextField,
  SecondaryButton,
} from "../../../../../ayudas/Themes";
import { PrimaryButton } from "../../../../Packages/PackageTheme";
import { AddNewScriptFileAsync } from "../../../Services/ServiceScriptPage";
import { enqueueSnackbar } from "notistack";

export default function FormCreateNewScript(props) {
  const { DataUser } = props;

  const [changeType, setchangeType] = useState(true);
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [file, setFile] = useState(null);
  const [extension, setExtension] = useState("default");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [platform, setPlatform] = useState("");
  const [platformLanguaje, setPlatformLanguaje] = useState("");
  let executionInstruccion = "";
  const [executionParameters, setExecutionParameters] = useState("");
  let executionPermissions = "";
  const [disabledSubmit, setdisabledSubmit] = useState(false);
  const setHandleCode = (e) => {
    setCode(e);
  };
  const handleCode = (e) => {
    if (changeType) {
      if (e.files[0]) {
        // setName(e.files[0].name);

        var extensionLanguaje = /[.]/.exec(e.files[0].name)
          ? "." + e.files[0].name.split(".").pop()
          : "";
        let bool = true;
        switch (extensionLanguaje) {
          case ".py":
            setPlatformLanguaje("phython");
            setPlatform("MultiPlatform");

            break;
          case ".pl":
            setPlatformLanguaje("perl");
            setPlatform("MultiPlatform");

            break;
          case ".rb":
            setPlatformLanguaje("ruby");
            setPlatform("MultiPlatform");

            break;
          case ".ps1":
            setPlatformLanguaje("powershell");
            setPlatform("MultiPlatform");

            break;
          case ".bat":
            setPlatformLanguaje("BATHWIN");
            setPlatform("Windows");

            break;
          case ".sh":
            setPlatformLanguaje("BATHLinux");
            setPlatform("GNULinux");

            break;
          default:
            bool = false;
            document.getElementById("scriptFile").innerHTML = "";
            enqueueSnackbar("Extension not supported",{variant:"error"});
            break;
        }
        if (bool) {
          // console.log(e.files[0]);
          setFile(e.files[0]);
        }
      }
    }
  };

  const setFormText = async () => {
    setdisabledSubmit(true);
    if (platformLanguaje === "") {
      if (changeType) {
        enqueueSnackbar("Add a file before trying to save",{variant:"info"});
      } else {
        enqueueSnackbar("Select Code Editor Language before trying to save",{variant:"info"});
      }
    } else if (changeType) {
      var object = {
        alias,
        description,
        file,
        platformLanguaje,
        platform,
        executionInstruccion,
        executionParameters,
        executionPermissions,
      };
      const msgResult = await AddNewScriptFileAsync(localStorage.getItem("token"), object);
      props.HandleReload(msgResult);
      setTimeout(() => {
        props.Result(msgResult);
      }, 1000);
    } else {
      var object2 = {
        name,
        extension,
        code,
        alias,
        description,
        platform,
        platformLanguaje,
        executionInstruccion,
        executionParameters,
        executionPermissions,
      };
      const msgResult = await AddNewScriptFileAsync(DataUser.token, object2);
      props.HandleReload(msgResult);
      setTimeout(() => {
        props.Result(msgResult);
      }, 1000);
    }
    setdisabledSubmit(false);
  };
  const HandleLanguajeSelect = (value) => {
    switch (value) {
      case "python":
        setPlatformLanguaje("phython");
        setExtension(".py");
        setPlatform("MultiPlatform");
        break;
      case "perl":
        setPlatformLanguaje(value);
        setExtension(".pl");
        setPlatform("MultiPlatform");

        break;
      case "ruby":
        setPlatformLanguaje(value);
        setExtension(".rb");
        setPlatform("MultiPlatform");

        break;
      case "powershell":
        setPlatformLanguaje(value);
        setExtension(".ps1");
        setPlatform("MultiPlatform");
        break;
      case "bat":
        setPlatformLanguaje("BATHWIN");
        setExtension(".bat");
        setPlatform("Windows");

        break;
      case "shell":
        setPlatformLanguaje("BATHLinux");
        setExtension(".sh");
        setPlatform("GNULinux");

        break;

      default:
        break;
    }
  };

  return (
    <Grid container spacing={1} backgroundColor="#090f1a" borderRadius={"8px"} paddingRight={"8px"}>
      <Grid item md={12} textAlign="center">
        <Typography variant="h5">New Script</Typography>
      </Grid>
      <Grid item md={12}>
        <Card style={{ padding: "15px", backgroundColor: "transparent", backgroundImage:"none" }}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            General Configuration
          </Typography>

          <Typography variant="body1" style={{ width: "100%" }}>
            Select Type:
            {"   "}
            <Button
              variant={changeType ? "contained" : "text"}
              onClick={() => {
                setchangeType(true);
              }}
            >
              File
            </Button>
            {"   "}
            <Button
              variant={changeType ? "text" : "contained"}
              onClick={() => {
                setchangeType(false);
              }}
            >
              Code Editor
            </Button>
          </Typography>

          {changeType ? (
            <>
              <Box style={{ marginTop: "25px" }}></Box>
              <FileUpload
                NameID="scriptFile"
                HandleFile={handleCode}
                ArtifactType={true}
                //   Error={forcecheck && xmlFile === null && ErrorField}
              />
              <Typography variant="caption">
                Extension support python ".py", perl ".pl", ruby ".rb",
                powershell ".ps1", bash ".bat", shell ".sh"
              </Typography>
            </>
          ) : (
            <>
              <FormInputTextField
                id="input-with-icon-textfield"
                label="Script Name*"
                variant="outlined"
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {/* <Select
                  style={{ width: "50%" }}
                  value={extension}
                  onChange={(e) => {
                    setExtension(e.target.value);
                  }}
                  defaultValue="disabled"
                >
                  <MenuItem value="default" disabled>
                    Select a extension
                  </MenuItem>
                  <MenuItem value=".rb">.rb</MenuItem>
                  <MenuItem value=".py">.py</MenuItem>
                  <MenuItem value=".bt">.bt</MenuItem>
                  <MenuItem value=".sh">.sh</MenuItem>
                  <MenuItem value=".pl">.pl</MenuItem>
                </Select> */}
              <CodeEditorViewer
                handleText={setHandleCode}
                HandleLanguajeSelect={HandleLanguajeSelect}
              ></CodeEditorViewer>
            </>
          )}
          <FormInputTextField
            id="input-with-icon-textfield"
            label="Script Alias"
            variant="outlined"
            style={{ width: "100%", marginTop: "10px" }}
            onChange={(e) => {
              setAlias(e.target.value);
            }}
          />
          <FormInputTextField
            style={{ marginTop: "15px" }}
            sx={{
              ".MuiFilledInput-root": {
                height: "100% !important",
                overflow: "auto",
                backgroundColor: "rgb(21, 19, 43) !important",
                borderRadius: "15px",
                border: "1px solid #464366",
              },
              ".MuiFilledInput-root:hover": {
                border: "1px solid #c59d48 !important",
              },
              ".MuiFilledInput-root:after": {
                borderBottom: "transparent !important",
              },
              ".MuiFilledInput-root:before": {
                borderBottom: "transparent !important",
              },
            }}
            color="secondary"
            label="Description*"
            multiline
            rows={6}
            variant="filled"
            fullWidth
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          {/* <FormControl sx={{ marginTop: "10px", minWidth: 120 }} fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">
              Select Platform*
            </InputLabel>
            <Select
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              }}
              className="SelectInput"
              label="Threat"
              value={platform}
              onChange={(e) => {
                setPlatform(e.target.value);
              }}
            >
              <MenuItem value="" disabled className="SelectItem">
                Select Platform
              </MenuItem>
              <MenuItem
                className="SelectItem"
                value="Windows"
              >
                Windows
              </MenuItem>
              <MenuItem
                className="SelectItem"
                value="GNULinux"
              >
                GNU/Linux
              </MenuItem>
              <MenuItem
                className="SelectItem"
                value="MultiPlatform"
              >
                MultiPlatform
              </MenuItem>
            </Select>
          </FormControl> */}
        </Card>
      </Grid>

      <Grid item md={12}>
        <Card style={{ padding: "15px" ,backgroundColor: "transparent", backgroundImage:"none" }}>
          <Typography style={{ marginTop: "15px" }} variant="h6">
            Optional parameters configuration fields
          </Typography>
          {/* <FormInputTextField
            id="input-with-icon-textfield"
            label="Execution instruction"
            fullWidth
            variant="outlined"
            style={{ marginTop: "15px" }}
            onChange={(e) => {
              setExecutionInstruccion(e.target.value);
            }}
          />
          <br /> */}

          <FormInputTextField
            id="input-with-icon-textfield"
            label="Execution parameters"
            fullWidth
            variant="outlined"
            style={{ marginTop: "15px" }}
            onChange={(e) => {
              setExecutionParameters(e.target.value);
            }}
          />
          <br />

          {/* <FormInputTextField
            id="input-with-icon-textfield"
            label="Execute permissions"
            fullWidth
            variant="outlined"
            style={{ marginTop: "15px" }}
            onChange={(e) => {
              setExecutionPermissions(e.target.value);
            }}
          /> */}
        </Card>
      </Grid>

      <Grid item md={12} textAlign="center">
        <PrimaryButton
          onClick={() => {
            setFormText();
          }}
          disabled={disabledSubmit}
        >
          Save Script
        </PrimaryButton>
        <SecondaryButton
          onClick={() => {
            props.handleClose();
          }}
        >
          Cancel
        </SecondaryButton>
      </Grid>
    </Grid>
  );
}
