import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { SecondaryButton } from "../../../../ayudas/Themes";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import CardMedia from "@mui/material/CardMedia";
import execimg from "../../../../assets/images/execution-icon-light.svg";
import { GetDate } from "../../../../ayudas/ScriptsFunctions";
const style = {
  boxShadow: "18px 4px 35px #00000026",
  borderRadius: "14px",
  maxWidth: "700px",
  margin: "0 auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "transparent",
  color: "white",
  p: 2,
};

export default function PopUpModalDetailsExecution(props) {
  const { GetOpenModal, InfoExecution } = props;
  const [open, setOpen] = useState(GetOpenModal);
  const [infoExecutionData, setInfoExecutionData] = useState(InfoExecution);

  const handleClose = () => {
    setOpen(false);
    props.effectModal(false);
  };
  useEffect(() => {
    setOpen(GetOpenModal);
  }, [GetOpenModal]);

  useEffect(() => {
    setInfoExecutionData(InfoExecution);
  }, [InfoExecution]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Card
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "#090f1a",
                border: "1px solid #1e1c3a",
                borderRadius: "8px",
                fontSize: "14px",
                padding: "3px 15px",
                color: "#8e97ad",
              }}
              sx={{ maxWidth: 600 }}
            >
              <CardHeader
                sx={{
                  "& .MuiCardHeader-title": {
                    fontSize: "21px",
                  },
                  "& .MuiCardHeader-subheader": {
                    fontSize: "19px",
                    color: "#8e97ad",
                    minWidth: "240px",
                    maxWidth: "395px",
                    overflow: "hidden",
                    whiteSpace: "initial",
                    textOverflow: "ellipsis",
                    textAlign: "justify",
                  },
                }}
                avatar={
                  <Avatar
                    sx={{ bgcolor: "transparent", fontSize: "22px" }}
                    aria-label="recipe"
                  >
                    <Typography style={{ color: "transparent" }}>De</Typography>
                  </Avatar>
                }
                action={
                  <SecondaryButton
                    onClick={handleClose}
                    style={{ margin: "0px" }}
                    aria-label="settings"
                  >
                    <CloseIcon style={{ color: "white" }} />
                  </SecondaryButton>
                }
                title="Emulation Details"
                subheader={`Emulation Name: ${infoExecutionData?.name}`}
              />

              <CardMedia
                component="img"
                style={{ width: "200px", height: "200px", margin: "auto" }}
                image={execimg}
                alt=""
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box
                      style={{
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "18px",
                        padding: "3px 15px",
                        color: "#8e97ad",
                      }}
                    >
                      Last Update:{" "}
                    </Box>
                    <Typography>
                      {GetDate(infoExecutionData?.lastModification)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "18px",
                        padding: "3px 15px",
                        color: "#8e97ad",
                      }}
                    >
                      Emulation Vector:
                    </Box>
                    <Typography>
                      {infoExecutionData?.vector === "RealExecution"
                        ? "Execution"
                        : infoExecutionData?.vector === "EPP" ||
                          infoExecutionData?.vector === "Epp"
                        ? "Endpoint"
                        : "Network"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "18px",
                        padding: "3px 15px",
                        color: "#8e97ad",
                      }}
                    >
                      Status emulation:
                    </Box>
                    <Typography>{infoExecutionData?.status}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      style={{
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "18px",
                        padding: "3px 15px",
                        color: "#8e97ad",
                      }}
                    >
                      Vector times:
                    </Box>
                    <Typography>
                      {infoExecutionData?.vector === "NetworkSecurity"
                        ? `Network: ${infoExecutionData.time_NetworkSecurity} seconds`
                        : infoExecutionData?.vector === "EPP"
                        ? `Network: ${infoExecutionData.time_NetworkSecurity} seconds,
                           Endpoint: ${infoExecutionData.time_EPP} seconds`
                        : infoExecutionData?.vector === "RealExecution"
                        ? `Network: ${infoExecutionData.time_NetworkSecurity} seconds,
                           Endpoint: ${infoExecutionData.time_EPP} seconds,
                           Execution: ${infoExecutionData.time_RealExecution} seconds`
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "18px",
                        padding: "3px 15px",
                        color: "#8e97ad",
                      }}
                    >
                      Endpoints
                    </Box>
                    <Typography
                      style={{
                        textAlign: "center",
                        maxHeight: "180px",
                        overflow: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      {infoExecutionData?.clients.length !== 0
                        ? infoExecutionData?.clients?.map((row) => {
                            return <div>{row.workUser[0].hostname}</div>;
                          })
                        : "The endpoints is not reachable"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        margin: "5px",
                        backgroundColor: "#0a0818",
                        border: "1px solid #1e1c3a",
                        borderRadius: "8px",
                        fontSize: "18px",
                        padding: "3px 15px",
                        color: "#8e97ad",
                      }}
                    >
                      Packages
                    </Box>
                    <Typography
                      style={{
                        textAlign: "center",
                        maxHeight: "180px",
                        overflow: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        {infoExecutionData?.packages.length !== 0
                          ? infoExecutionData?.packages.map((row) => {
                              return (
                                <p
                                  style={{
                                    minWidth: "240px",
                                    maxWidth: "495px",
                                    overflow: "hidden",
                                    whiteSpace: "initial",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                  }}
                                >
                                  {row.name}
                                </p>
                              );
                            })
                          : "Information about their one or many packages deleted"}
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
                {/* <PopUpEditOnDemand
                DataUser={DataUser}
                InfoExecutionOnDemand={infoExecutionData}
              ></PopUpEditOnDemand> */}
              </CardContent>
              {/* <PopUpEditSchduledModal
                  DataUser={DataUser}
                  InfoExecutionDataScheduled={infoExecutionScheduled}
                ></PopUpEditSchduledModal> */}
            </Card>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
