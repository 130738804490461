import { useState } from "react";
import {
  Box,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import "../../../../General.scss";
import "../../styles/User.scss";
import {
  ActionButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../../ayudas/Themes";
import IconUser from "../../../../assets/images/users-icon-white.svg";
import Profile1 from "../../../../assets/images/select-profile-1.png";
import Profile2 from "../../../../assets/images/select-profile-2.png";
import Profile3 from "../../../../assets/images/select-profile-3.png";
import Profile4 from "../../../../assets/images/select-profile-4.png";
import Profile5 from "../../../../assets/images/select-profile-5.png";
import Profile6 from "../../../../assets/images/select-profile-6.png";
import Profile7 from "../../../../assets/images/select-profile-7.png";
import Profile8 from "../../../../assets/images/select-profile-8.png";
import Profile9 from "../../../../assets/images/select-profile-9.png";

import Code from "../../assets/QR.png";
import { enqueueSnackbar } from "notistack";
import { env } from "../../../../env";

export default function Newlmodal(props) {
  const [open, setOpen] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [rol, setRol] = useState(null);
  const [Icon, setIcon] = useState(0);
  const types = ["", "admin", "user"];

  const handleIcon = (Select) => {
    setIcon(Select);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleName = (e) => {
    if (e.target.value !== " ") {
      setfirstName(e.target.value);
    }
  };
  const handleLastName = (e) => {
    if (e.target.value !== " ") {
      setLastName(e.target.value);
    }
  };

  const handleEmail = (e) => {
    if (e.target.value !== " ") {
      setEmail(e.target.value);
    }
  };

  const handleClose = () => {
    setIcon(0);
    setfirstName("");
    setLastName("");
    setEmail("");
    setRol(null);
    setOpen(false);
  };
  const handleVerify = () => {
    setOpenVerify(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (firstName && lastName && rol && email) {
      if (!email.match(regexEmail)) {
        enqueueSnackbar("email incorrect or undefined", { variant: "error" });
      } else {
        const body = {
          enabled: true,
          username: email,
          email: email,
          firstName: firstName,
          lastName: lastName,
          credentials: [
            {
              type: "password",
              value: "123456",
              temporary: false,
            },
          ],
          attributes: {
            rol: rol,
            avatar: Icon,
          },
        };
        var url = `${env.REACT_APP_KEYCLOAK_INSTANCE}/admin/realms/${env.REACT_APP_KEYCLOAK_REALM}/users/`;
        var requestHeaders = new Headers();
        requestHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );
        requestHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: "POST",
          headers: requestHeaders,
          body: JSON.stringify(body),
          redirect: "follow",
        };
        await fetch(url, requestOptions)
          .then((response) => {
            if (response.ok || response.status === 204) {
              props.Reload(body);
              setOpen(false);
              enqueueSnackbar("update data successfully", {
                variant: "success",
              });
            } else {
              console.log("error", response.statusText);
            }
          })
          .catch((error) => console.log("error", error));
        props.Reload();
        handleClose();
      }
    } else {
      enqueueSnackbar("please enter all field *", { variant: "error" });
    }
  };
  const secondAuth = (
    <Box className="contenido">
      <Box className="header">
        <Typography className="headerTitle">
          Two Factor Authentication
        </Typography>
        <Typography variant="body1" color="white">
          Two-factor authentication is currently disabled.
          <br />
          If you enable two-factor authentication followinng instructions below,
          you wil be asked to provide an extra verification code next time to
          login.
          <br />
          <ul>
            <li>Please download your preference App Authenticator.</li>
            <li>
              Scan the QR code below or enter key manually using the
              application:
              <img src={Code} width={200} alt="" />
            </li>
            <li>
              Type the 6-digit code from App Authetication to verify your
              configuration
              <input></input>
            </li>
          </ul>
        </Typography>
      </Box>
      <Box style={{ width: "100%", textAlign: "center" }}>
        <SecondaryButton onClick={handleVerify}>Cancel</SecondaryButton>
        <ActionButton onClick={handleVerify}>Verify</ActionButton>
      </Box>
    </Box>
  );
  const body = (
    <Box className="contenido">
      <Modal open={openVerify} onClose={handleVerify}>
        {secondAuth}
      </Modal>
      <Box className="header">
        <Typography className="headerTitle">Add New User</Typography>
      </Box>
      <Grid container>
        <Grid item size={12} className="bdr-grid">
          <Box>
            <Typography className="comn-label-dash">
              Select Profile Picture
            </Typography>
            <Box
              style={{
                padding: "0",
                flexBasis: "0",
                flexGrow: "1",
                textAlign: "center",
              }}
            >
              <img
                src={Profile1}
                alt=""
                onClick={handleIcon.bind(this, 0)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: Icon === 0 ? " 75px" : "55px",
                  height: Icon === 0 ? " 75px" : "55px",
                  marginLeft: "15px",
                }}
              />
              <img
                src={Profile2}
                alt=""
                onClick={handleIcon.bind(this, 1)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: Icon === 1 ? " 75px" : "55px",
                  height: Icon === 1 ? " 75px" : "55px",
                  marginLeft: "15px",
                }}
              />
              <img
                src={Profile3}
                alt=""
                onClick={handleIcon.bind(this, 2)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: Icon === 2 ? " 75px" : "55px",
                  height: Icon === 2 ? " 75px" : "55px",
                  marginLeft: "15px",
                }}
              />
              <img
                src={Profile4}
                alt=""
                onClick={handleIcon.bind(this, 3)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: Icon === 3 ? " 75px" : "55px",
                  height: Icon === 3 ? " 75px" : "55px",
                  marginLeft: "15px",
                }}
              />
              <img
                src={Profile5}
                alt=""
                onClick={handleIcon.bind(this, 4)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: Icon === 4 ? " 75px" : "55px",
                  height: Icon === 4 ? " 75px" : "55px",
                  marginLeft: "15px",
                }}
              />
              <img
                src={Profile6}
                alt=""
                onClick={handleIcon.bind(this, 5)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: Icon === 5 ? " 75px" : "55px",
                  height: Icon === 5 ? " 75px" : "55px",
                  marginLeft: "15px",
                }}
              />
              <img
                src={Profile7}
                alt=""
                onClick={handleIcon.bind(this, 6)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: Icon === 6 ? " 75px" : "55px",
                  height: Icon === 6 ? " 75px" : "55px",
                  marginLeft: "15px",
                }}
              />
              <img
                src={Profile8}
                alt=""
                onClick={handleIcon.bind(this, 7)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: Icon === 7 ? " 75px" : "55px",
                  height: Icon === 7 ? " 75px" : "55px",
                  marginLeft: "15px",
                }}
              />
              <img
                src={Profile9}
                alt=""
                onClick={handleIcon.bind(this, 8)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: Icon === 8 ? " 75px" : "55px",
                  height: Icon === 8 ? " 75px" : "55px",
                  marginLeft: "15px",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          size={6}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "15px",
          }}
        >
          <Box>
            <Typography className="comn-label-dash">First Name*</Typography>
            <Box>
              <input
                id="FName"
                type="text"
                helperText="Enter the field"
                className="input-style-users"
                placeholder="First Name"
                onChange={handleName}
                value={firstName}
                //error={!ErrorState}
              ></input>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          size={6}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "15px",
          }}
        >
          <Box>
            <Typography className="comn-label-dash">Last Name*</Typography>
            <Box>
              <input
                type="text"
                className="input-style-users"
                placeholder="Last Name"
                id="LName"
                onChange={handleLastName}
                value={lastName}
              ></input>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          size={8}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "15px",
          }}
        >
          <Box>
            <Typography className="comn-label-dash">Email Address*</Typography>
            <Box>
              <input
                type="text"
                className="input-style-users"
                placeholder="Email Address"
                id="Email"
                onChange={handleEmail}
                value={email}
              ></input>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          size={4}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "15px",
          }}
        >
          <Box>
            <Typography className="comn-label-dash">Role*</Typography>
            <Box>
              <FormControl style={{ width: "100%" }}>
                <InputLabel
                  id="select-label"
                  style={{
                    display: !rol ? "block" : "none",
                    fontSize: "14px",
                  }}
                ></InputLabel>
                <select
                  className="input-style-users"
                  sx={{
                    ".MuiSelect-select": {
                      backgroundColor: "transparent !important",
                    },
                    ".MuiSelect-select:hover": {
                      border: "none",
                    },
                  }}
                  id="Rol"
                  style={{
                    textTransform: "capitalize",
                    fontSize: "16px",
                    marginTop: "4px",
                  }}
                  onChange={(e) => {
                    setRol(e.target.value);
                  }}
                >
                  {types.map((el, index) => {
                    return (
                      <option
                        value={el}
                        style={{
                          textTransform: "capitalize",
                          fontSize: "16px",
                          color: "white",
                        }}
                        key={index}
                      >
                        {el}
                      </option>
                    );
                  })}
                </select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        {/* <Grid
          item
          md={6}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
          }}
        >
          <Box>
            <Typography className="comn-label-dash">Password*</Typography>
            <Box>
              <PasswordInput Value={ChangePasword} LabelInput="Password" />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "15px",
          }}
        >
          <Box>
            <Typography className="comn-label-dash">
              Confirm Password*
            </Typography>
            <Box>
              <PasswordInput
                ErrorMessage={ErrorMessage}
                Value={ChangeRePasword}
                LabelInput="Confirm Password"
              />
            </Box>
          </Box>
        </Grid> */}
        {/* <Grid
          item
          size={6}
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginBottom: "15px",
          }}
        > */}
          {/* <Box>
            <Typography className="comn-label-dash">
              Second Validation
            </Typography>
            <Box>
              <FormGroup>
                <FormControlLabel
                  style={{ color: "white" }}
                  control={<GreenSwitch onChange={handleOpenVerify} />}
                  label={chekedValidate ? "Active" : "Inactive"}
                />
              </FormGroup>
            </Box>
          </Box> */}
        {/* </Grid> */}
        <Grid item size={12} textAlign="center">
          <Box>
            <ActionButton onClick={handleSubmit}>Save</ActionButton>
            <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <PrimaryButton variant="outlined" onClick={handleOpen}>
        <img
          src={IconUser}
          alt=""
          style={{ width: "20px", marginRight: "7px", fill: "#fff" }}
        />
        New user
      </PrimaryButton>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
}
