import React from "react";
import { getArtifactsPageAsync } from "../../../../Services/Artifacts";
import { GetDate } from "../../../../ayudas/ScriptsFunctions";
import { Box } from "@mui/material";
import ArtifactTable from "./ArtifactTable";
import FlowButtons from "./FlowButtons";
import "../../../../General.scss";

class ArtifactsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artifacts: [],
      totalRows: 1,
      FilterName: "",
      page:0,
    };
    this.artifactsSelectedInPage =[];
  }

  getPage = async (size, page, order, filter, filterName) => {
    const filterData = {
      pageSize: size,
      pageNumber: page,
      order: order,
      filter: filter,
      filterName: {name:filterName},      
    };
    
      const response = await getArtifactsPageAsync(
        size,
        page + 1,
        filterData.filter,
        filterData.order,        
        filterData.filterName
      );
      this.setArtifactData(response);    
  }

  setArtifactData =(response)=> {
    this.artifactsSelectedInPage = [];
    const artifactDto = response?.artifacts.map((artifact) => { 
        let artifactDto = {
            id: artifact.id,
            name: artifact.name,
            lastModification: GetDate(artifact.lastModification),
            threatType: artifact.threatType==="Midd"?"Mid":artifact.threatType,
            tagTest: artifact.tagTest,
            md5:artifact.md5
        }
        this.props.valueInput.forEach((artifact) => { 
          if (artifact.id === artifactDto.id) {
            this.artifactsSelectedInPage.push(artifact.id);}
        });
        return artifactDto;
    })
    let total=response?.info?.neto?response.info.neto:1;
    this.setState({
      artifacts: artifactDto === undefined ? [] : artifactDto,
      totalRows: total,
    });
  }

  onSelectionModelChange =(selectionModelId)=> {
    if(this.props.EditMode.enable === true){
      this.props.onArtifactsChange(selectionModelId);
    }else{
      this.props.onArtifactsChange(selectionModelId);
    }

  }
  
  render() {
    return (
      <Box>
        <ArtifactTable gmt = {this.props.DataUser.GMT}
                       handlePage = {this.getPage}
                       rowCount = {this.state.totalRows}
                       rows = {this.state.artifacts}
                       artifactsSelectedInPage = {this.props.valueInput}
                       handleSelectionModelChange = {this.onSelectionModelChange}
                       labelDisplay={this.props.labelDisplay}
                       edtiMode={this.props.EditMode.enable}
                       >
                      
        </ArtifactTable>
        <FlowButtons disableNext={(this.props.valueInput.length === 0 || this.props.valueInput.length > 30) ? true:false}
                     labelNext= "Complete"
                     handleNext= {this.props.handleNext}
                     handleBack={this.props.handleBack}
        />
      </Box>
    );
  }
}

export default ArtifactsForm;
