import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  FormInputTextField,
  PrimaryButton,
} from "../../../../../../../ayudas/Themes";

export default function StepNameScript(props) {
  const { valueName, valueDescription } = props;
  const [Name, setName] = useState(valueName);
  const [description, setDescription] = useState(valueDescription);
  const [bool, setBool] = useState(true);

  const validateInputs = useCallback(() => {
    let x = Name.substring(0, 1);
    if (description !== null) {
      let x2 = description.substring(0, 1);
      if (x !== " " && x2 !== " ") {
        if (Name !== "" && description !== "") {
          setBool(false);
        } else {
          setBool(true);
        }
      } else {
        setBool(true);
      }
    }
  }, [Name, description]);

  useEffect(() => {
    validateInputs();
  }, [Name, description, validateInputs]);

  const HandleName = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== "") {
        setName(e.target.value);
      } else {
        setName("");
      }
    }
  };
  const HandleDescription = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== "") {
        setDescription(e.target.value);
      } else {
        setDescription("");
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid rgb(30, 28, 58)",
          backgroundColor: "rgb(21, 19, 43)",
          padding: "20px",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            borderRadius: "5px",
            // backgroundColor: "rgb(28, 26, 51)",
          }}
        >
          <FormInputTextField
            fullWidth
            onChange={(e) => {
              HandleName(e);
            }}
            color="secondary"
            variant="outlined"
            value={Name}
            label="Name *"
            style={{ color: "#66638f", marginBottom: "10px" }}
          />
          <FormInputTextField
            sx={{
              ".MuiFilledInput-root": {
                height: "100% !important",
                overflow: "auto",
                backgroundColor: "rgb(21, 19, 43) !important",
                borderRadius: "15px",
                border: "1px solid #464366",
              },
              ".MuiFilledInput-root:hover": {
                border: "1px solid #c59d48 !important",
              },
              ".MuiFilledInput-root:after": {
                borderBottom: "transparent !important",
              },
              ".MuiFilledInput-root:before": {
                borderBottom: "transparent !important",
              },
            }}
            color="secondary"
            label="Description*"
            multiline
            rows={6}
            variant="filled"
            fullWidth
            value={description}
            onChange={(e) => {
              HandleDescription(e);
            }}
          />
        </Box>
      </Box>

      <PrimaryButton
        disabled={bool}
        style={{ float: "right" }}
        onClick={() => {
          props.HandleValueInputName(Name);
          props.HandleValueInputDescription(description);
          props.HandleActiveStepNext();
        }}
      >
        Next
      </PrimaryButton>
    </>
  );
}
