import { useState } from "react";
import {
  Box,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import "../../../../General.scss";
import "../../styles/User.scss";
import { ActionButton, SecondaryButton } from "../../../../ayudas/Themes";
import Profile1 from "../../../../assets/images/select-profile-1.png";
import Profile2 from "../../../../assets/images/select-profile-2.png";
import Profile3 from "../../../../assets/images/select-profile-3.png";
import Profile4 from "../../../../assets/images/select-profile-4.png";
import Profile5 from "../../../../assets/images/select-profile-5.png";
import Profile6 from "../../../../assets/images/select-profile-6.png";
import Profile7 from "../../../../assets/images/select-profile-7.png";
import Profile8 from "../../../../assets/images/select-profile-8.png";
import Profile9 from "../../../../assets/images/select-profile-9.png";
import { postQRValidation, postValidationTOTP } from "../../services/Users";
import { useSnackbar } from "notistack";
import { env } from "../../../../env";

export default function Detailmodal(props) {
  const { User, DataUser, expandButton, rol } = props;
  const [open, setOpen] = useState(false);
  const [firstName, setfirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [fk_rol, setRol] = useState();
  const types = ["admin", "user"];
  const [Icon, setIcon] = useState(0);
  const [openVerify, setOpenVerify] = useState(false);
  const [openVerifyCode, setOpenVerifyCode] = useState(false);
  const [chekedValidate, setchekedValidate] = useState(false);
  // const [disablebtn, setdisablebtn] = useState(false);
  const [qr, setQR] = useState("");
  const [VerificationCode, setVerificationCode] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  // const handleOpenVerify = (event) => {
  //   setOpenVerify(event.target.checked);
  //   setchekedValidate(event.target.checked);
  //   validatingForQR(event.target.checked);
  // };

  const handleIcon = (Select) => {
    setIcon(Select);
  };
  const handleOpen = async () => {
    // if (User.actions?.isTOTP === 1) {
    //   setchekedValidate(true);
    // } else {
    //   if (User.actions?.isTOTP === 0) {
    //     setchekedValidate(false);
    //   } else {
    //     let getUser = await getUserAsync(
    //       DataUser.token,
    //       DataUser.user.result._id
    //     );
    //     if (getUser.isTOTP === 1) {
    //       setchekedValidate(true);
    //     } else {
    //       setchekedValidate(false);
    //     }
    //   }
    // }
    if (User.urlIma) {
      if (typeof User.urlIma === "number") {
        setIcon(User.urlIma - 1);
      } else {
        let splitdata = User.urlIma.split("/");
        let image = splitdata[splitdata.length - 1].split(".");
        let number = parseInt(image[0], 10);
        setIcon(number);
      }
    }
    setfirstName(User.firstName);
    setLastName(User.lastName);
    setEmail(User.email);
    setRol(User.rol);
    // let user = DataUser.user.result.email;
    // if (user === User.email) {
    //   setdisablebtn(false);
    // } else {
    //   setdisablebtn(true);
    // }

    setOpen(true);
  };

  // const validatingForQR = async (event) => {
  //   if (event) {
  //     const userDataValidate = {
  //       activation: "Y",
  //     };
  //     const datareq = await postQRValidation(DataUser.token, userDataValidate);
  //     setQR(datareq);
  //   } else {
  //     setOpenVerify(false);
  //     setOpenVerifyCode(true);
  //   }
  // };
  const handleCloseTOTP = () => {
    setchekedValidate(true);
    setOpenVerifyCode(false);
    setVerificationCode("");
  };

  const handleCloseTOTPVerify = () => {
    setOpenVerify(false);
    setchekedValidate(false);
    setVerificationCode("");
  };

  const validatingForTOTPData = async () => {
    const userDataValidate = {
      authPayload: VerificationCode,
    };
    if (chekedValidate) {
      const datareq = await postValidationTOTP(
        DataUser.token,
        userDataValidate
      );
      if (!datareq?.msg) {
        setchekedValidate(false);
      }
      if (datareq?.msg === "unverified") {
        setOpenVerify(false);
        setchekedValidate(false);
      } else {
        setTimeout(() => {
          props.Reload();
          setOpenVerify(false);
          setOpenVerifyCode(false);
          handleClose();
          setOpen(false);
          setQR("");
        }, 2000);
      }
    }
  };
  const desactivationSend = async () => {
    if (openVerifyCode) {
      const userDataValidate = {
        activation: "n",
        authPayload: VerificationCode,
      };
      await postQRValidation(
        DataUser.token,
        userDataValidate,
        "Success Desactivation"
      );
    }

    setTimeout(() => {
      setOpenVerifyCode(false);
      handleClose();
      setOpen(false);
    }, 1000);
  };

  const handleName = (e) => {
    if (e.target.value !== " ") {
      setfirstName(e.target.value);
    }
  };
  const handleLastName = (e) => {
    if (e.target.value !== " ") {
      setLastName(e.target.value);
    }
  };

  const handleEmail = (e) => {
    if (e.target.value !== " ") {
      setEmail(e.target.value);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpenVerify(false);
    setchekedValidate(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      id: User.id,
      enabled: true,
      email: email,
      firstName: firstName,
      lastName: lastName,
      attributes: {
        rol: rol,
        avatar: Icon,
      },
    };

    var url = `${env.REACT_APP_KEYCLOAK_INSTANCE}/admin/realms/${env.REACT_APP_KEYCLOAK_REALM}/users/${User.id}`;
    var requestHeaders = new Headers();
    requestHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    requestHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
    };
    await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok || response.status === 204) {
          props.Reload(body);
          setOpen(false);
          enqueueSnackbar("update data successfully", { variant: "success" });
        } else {
          console.log("error", response.statusText);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleVerify = () => {
    validatingForTOTPData();
  };

  const handleVerifyCode = () => {
    setOpenVerifyCode(false);
  };

  const handleCodeVerification = (event) => {
    setVerificationCode(event.target.value);
  };

  const MFAModule = (
    <Box
      className="contenido"
      style={{ width: "auto", color: "#FFF", textAlign: "center" }}
    >
      <Box className="header">
        <Typography className="headerTitle" style={{ margin: "15px auto" }}>
          Two Factor Authentication
        </Typography>
        <Typography variant="body1" style={{ margin: "15px auto" }}>
          Enter the MFA code to complete the login
        </Typography>
        <Typography variant="subtitle1" style={{ margin: "15px auto" }}>
          <input
            autoFocus
            style={{ width: "300px" }}
            type="text"
            className="input-style-users"
            maxLength="6"
            onChange={handleCodeVerification}
          ></input>
        </Typography>
      </Box>
      <Box style={{ width: "100%", textAlign: "center" }}>
        <SecondaryButton onClick={handleCloseTOTP}>Cancel</SecondaryButton>
        <ActionButton onClick={desactivationSend}>Send</ActionButton>
      </Box>
    </Box>
  );

  const secondAuth = (
    <Box className="contenido">
      <Box className="header">
        <Typography className="headerTitle">
          Two Factor Authentication
        </Typography>
        <Typography variant="body1" color="white">
          Two-factor authentication is currently disabled.
          <br />
          If you enable two-factor authentication followinng instructions below,
          you wil be asked to provide an extra verification code next time to
          login.
          <br />
          <ul>
            <li>Please download your preference App Authenticator.</li>
            <li>
              Scan the QR code below or enter key manually using the
              application:
              <img src={qr} width={200} style={{ marginTop: "7px" }} alt="" />
            </li>
            <li>
              Type the 6-digit code from App Authetication to verify your
              configuration
              <input
                autoFocus
                style={{ width: "300px", marginTop: "7px" }}
                type="text"
                className="input-style-users"
                onChange={handleCodeVerification}
                maxLength="6"
              ></input>
              {/* <SecondaryButton style={{marginLeft:"30px"}} onClick={validatingForTOTPData}>Verify code</SecondaryButton> */}
            </li>
          </ul>
        </Typography>
      </Box>
      <Box style={{ width: "100%", textAlign: "center" }}>
        <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
        <ActionButton onClick={handleVerify}>Verify</ActionButton>
      </Box>
    </Box>
  );

  const body = (
    <Box className="contenido">
      <Modal open={openVerify} onClose={handleCloseTOTPVerify}>
        {secondAuth}
      </Modal>
      <Box className="header">
        <Box className="header">
          <Typography className="headerTitle">Edit User</Typography>
        </Box>
        <Grid container>
          <Grid item size={12} className="bdr-grid">
            <Box>
              <Typography className="comn-label-dash">
                Select Profile picture
              </Typography>
              <Box
                style={{
                  padding: "0",
                  flexBasis: "0",
                  flexGrow: "1",
                  textAlign: "center",
                }}
              >
                <img
                  src={Profile1}
                  alt=""
                  onClick={handleIcon.bind(this, 0)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 0 ? " 75px" : "55px",
                    height: Icon === 0 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile2}
                  alt=""
                  onClick={handleIcon.bind(this, 1)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 1 ? " 75px" : "55px",
                    height: Icon === 1 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile3}
                  alt=""
                  onClick={handleIcon.bind(this, 2)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 2 ? " 75px" : "55px",
                    height: Icon === 2 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile4}
                  alt=""
                  onClick={handleIcon.bind(this, 3)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 3 ? " 75px" : "55px",
                    height: Icon === 3 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile5}
                  alt=""
                  onClick={handleIcon.bind(this, 4)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 4 ? " 75px" : "55px",
                    height: Icon === 4 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile6}
                  alt=""
                  onClick={handleIcon.bind(this, 5)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 5 ? " 75px" : "55px",
                    height: Icon === 5 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile7}
                  alt=""
                  onClick={handleIcon.bind(this, 6)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 6 ? " 75px" : "55px",
                    height: Icon === 6 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile8}
                  alt=""
                  onClick={handleIcon.bind(this, 7)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 7 ? " 75px" : "55px",
                    height: Icon === 7 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile9}
                  alt=""
                  onClick={handleIcon.bind(this, 8)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 8 ? " 75px" : "55px",
                    height: Icon === 8 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            size={6}
            style={{
              paddingRight: "15px",
              paddingLeft: "15px",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography className="comn-label-dash">First name</Typography>
              <Box>
                <input
                  id="FName"
                  type="text"
                  className="input-style-users"
                  onChange={handleName}
                  value={firstName}
                ></input>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            size={6}
            style={{
              paddingRight: "15px",
              paddingLeft: "15px",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography className="comn-label-dash">Last name</Typography>
              <Box>
                <input
                  type="text"
                  className="input-style-users"
                  id="LName"
                  onChange={handleLastName}
                  value={lastName}
                ></input>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            size={env.REACT_APP_INTERNAL_CRUD_USER === "true" ? 6 : 12}
            style={{
              paddingRight: "15px",
              paddingLeft: "15px",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography className="comn-label-dash">Email address</Typography>
              <Box>
                <input
                  type="text"
                  className="input-style-users"
                  placeholder="Email Address"
                  id="Email"
                  onChange={handleEmail}
                  value={email}
                ></input>
              </Box>
            </Box>
          </Grid>
          {env.REACT_APP_INTERNAL_CRUD_USER === "true" && (
            <Grid
              item
              size={6}
              style={{
                paddingRight: "15px",
                paddingLeft: "15px",
                marginBottom: "15px",
              }}
            >
              <Box>
                <Typography className="comn-label-dash">Role</Typography>
                <Box style={{ display: "flex" }}>
                  <FormControl style={{ width: "800px" }}>
                    <InputLabel
                      id="select-label"
                      style={{
                        display: !fk_rol ? "block" : "none",
                        fontSize: "14px",
                      }}
                    ></InputLabel>
                    <select
                      className="input-style-users"
                      labelId="select"
                      id="Rol"
                      style={{
                        textTransform: "capitalize",
                        fontSize: "16px",
                        color: "white",
                        marginTop: "4px",
                      }}
                      value={fk_rol}
                      disabled={DataUser.user.result.rol === "user"}
                      onChange={(e) => {
                        setRol(e.target.value);
                      }}
                    >
                      {types.map((el) => {
                        return (
                          <option
                            key={el}
                            value={el}
                            style={{
                              textTransform: "capitalize",
                              fontSize: "16px",
                              color: "white",
                            }}
                          >
                            {el}
                          </option>
                        );
                      })}
                    </select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
          )}
          {/*<Grid item size={6}>
            <Box style={{ width: "100%" }}>
              <FormGroup style={{ position: "relative", left: "25px" }}>
                <Typography
                  style={{ width: "200px" }}
                  className="comn-label-dash"
                >
                  Second Validation
                </Typography>

                <FormControlLabel
                  style={{ marginTop: "15px", color: "#fff" }}
                  control={
                    <SecondModeSwitch
                      checked={chekedValidate}
                      onChange={handleOpenVerify}
                      disabled={disablebtn}
                    />
                  }
                  label={chekedValidate ? "Active" : "Inactive"}
                />
              </FormGroup>
            </Box>
          </Grid> */}
          <Grid item size={12}>
            {/* <ActionButton
              onClick={() => {
                enqueueSnackbar("hola", { variant: "info" });
              }}
            >
              notific
            </ActionButton> */}
            <Box style={{ textAlign: "center" }}>
              <ActionButton onClick={handleSubmit}>Save</ActionButton>
              <SecondaryButton
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </SecondaryButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  return (
    <div style={{ display: "inline-block" }}>
      {expandButton === true ? (
        <>
          <Box style={{ display: "flex", width: "100%" }} onClick={handleOpen}>
            <Edit style={{ margin: "15px 10px 15px 15px", color: "#fafafa" }} />

            <p>Edit Profile</p>
          </Box>
        </>
      ) : (
        <>
          {/* <Tooltip title="Edit"> */}
          <IconButton
            className="iconButtonSize"
            style={{ color: "#66638f", display: "inline-block" }}
            onClick={handleOpen}
          >
            <Edit style={{ color: "#fafafa" }} />
          </IconButton>
          {/* </Tooltip> */}
        </>
      )}

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
      <Modal open={openVerifyCode} onClose={handleVerifyCode}>
        {MFAModule}
      </Modal>
    </div>
  );
}
