import { useCallback, useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  Grid,
  Tooltip,
  Skeleton,
} from "@mui/material";
import "./StyleReport.scss";
import ArrowIcon from "../../../assets/images/back-arrow-step.svg";
import SucessIcon from "../../../assets/images/success-table-icon.svg";
import FailIcon from "../../../assets/images/fail-table-icon.svg";

import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import { openPDFDetails } from "../../../ayudas/ExportPdf/PdfDetails";
import { openPdfInfo } from "../../../ayudas/ExportPdf/Pdfinfo";
import { openPDFResume } from "../../../ayudas/ExportPdf/PdfResume";
import {
  ActionButton,
  CostumTab,
  CostumTabs,
  ReportModeSwitch,
} from "../../../ayudas/Themes";
import ModalGeneralReport from "./ModalGeneralReport";
import "./Style.scss";
import { WarningImage, WarningText } from "./ConstansReport";
import Cyclelife from "./CycleLifeModal/Cyclelife";
import ExecutionDashboard from "./ExecutionDashboard/ExecutionDasboard";
import TableReportToAgentsAndArtifacts from "./TableReportToAgentsAndArtifacts/TableReportToAgentsAndArtifacts";
import { ArrayDates, ExportExcel, GenerateDataReport } from "./ServicesReport";
import { LoadingButton } from "@mui/lab";
import ModalWizard from "./ModalWizard";
import DataListTypes from "../../../Scripts/DataListArtifactsTypes";

export default function ReportExecution(props) {
  const { ReportDataProps, DataUser } = props;
  const [ReportData, setReportData] = useState([]);
  const [Selected, setSelected] = useState(false);
  const [TableBy, setTableBy] = useState(true);
  const [exportData, setexportData] = useState([]);
  const [index, setIndex] = useState(0);
  const [typeStatus, settypeStatus] = useState(true); //True Attack - False Defend
  const [Dates, setDates] = useState([]);
  const [value, setValue] = useState(0);
  const [dateselect, setdateselect] = useState({});

  const GenerateInformation = useCallback(
    async (body = []) => {
      let checkedData = await GenerateDataReport(
        DataUser,
        ReportDataProps,
        body
      );
      setReportData(checkedData);
      setSelected(true);
      if (checkedData && checkedData.FoundMissData !== 1) {
        GenerateReportXlXS(typeStatus, checkedData);
        let dates = ReportDataProps.result ? ArrayDates(ReportDataProps.result) : [];
        setDates(dates);
      }
      DataListTypes(checkedData);
    },
    [DataUser, ReportDataProps, typeStatus]
  );
  useEffect(() => {
    GenerateInformation();
  }, [GenerateInformation]);

  const GenerateReportXlXS = (status, data) => {
    if (data && data.tabledata1) {
      let excel = ExportExcel(status, data.tabledata1, data.vector);
      setexportData(excel);
    }
  };

  const GenerateNewInform = (data) => {
    let day = new Date();
    let GMT = day.toString().split("GMT")[1].split(" ")[0];
    let value = parseInt(
      GMT.charAt(0) + GMT.charAt(1) + GMT.charAt(2),
      10
    ).toFixed(0);
    let body = {
      date: data.date,
      time: data.hour,
      utc: parseInt(value),
    };
    setdateselect(body);
    GenerateInformation(JSON.stringify(body));
  };

  const ExportPDF = (array) => {
    openPDFResume(array);
  };
  const ExportPDFDetails = (array) => {
    openPDFDetails(array);
  };
  const ExportPDFWarnings = () => {
    openPdfInfo(
      ReportData.ReportData,
      ReportData.FoundMissData,
      Dates[value].label
    );
  };
  const handleChange = (event) => {
    let text = event.target.innerText;
    let newValue = "";
    for (let index = 0; index < Dates.length; index++) {
      const element = Dates[index];
      if (text === element.label) {
        newValue = element.index;
        break;
      }
    }
    setValue(newValue);
    let data = Dates[parseInt(newValue)];
    GenerateNewInform(data);
  };
  return (
    <>
      <Grid container spacing={3} >
        <Grid item md={12}>
          {Dates ? (
            <CostumTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              style={{ padding: "7px 0px 0px 7px ", backgroundColor:"#090f1a" }}
            >
              {Dates.map((element, index) => (
                <CostumTab
                  value={index}
                  label={element.label}
                  style={{
                    marginTop: "4px",
                    marginLeft: "4px",
                    borderColor:"#090f1a",
                    boxShadow:
                      value === element.index
                        ? `0px 0px 10px 3px ${
                            typeStatus ? "#790000" : "#1e05ad"
                          }`
                        : "none",
                  }}
                />
              ))}
            </CostumTabs>
          ) : (
            <Skeleton variant="rectangular" width={210} height={118} />
          )}
        </Grid>
      </Grid>
      {!ReportData.CheckData ? (
        <LoadingButton
          style={{ width: "90%", height: "300px", fontSize: "50px" }}
          loading
          loadingIndicator="Loading..."
          variant="outlined"
        >
          Fetch data
        </LoadingButton>
      ) : null}
      {ReportData.FoundMissData > 0 ? (
        <Box
          style={{
            width: "100%",
            border: "6px solid #66638f63",
            borderTop: "0px",
          }}
        >
          <IconButton
            onClick={() => {
              props.Back();
            }}
            style={{ display: "inline-block" }}
          >
            <img src={ArrowIcon} alt="" />
          </IconButton>
          <Typography
            style={{
              display: "inline-block",
              fontSize: "24px",
              color: "#ffffff",
              fontWeight: "600",
            }}
          >
            Emulation Report
          </Typography>
          <br />
          <Tooltip title={ReportData.ReportData.name}>
            <Typography
              variant="h6"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "350px",
                whiteSpace: "nowrap",
                color: "white",
                display: "inline-block",
                marginTop: "15px",
                marginLeft: "15px",
              }}
            >
              {"Emulation Name: " + ReportData.ReportData.name}
            </Typography>
          </Tooltip>
          <Box className="CardInfo" style={{ margin: "auto", width: "auto" }}>
            <Box className="BodyContent">
              <Box
                style={{
                  display: "flex",
                  width: "auto",
                  borderRadius: "0px 15px 15px 0px",
                }}
              >
                {WarningImage[ReportData.FoundMissData]}
              </Box>
            </Box>
            <Box style={{ width: "100%" }}>
              <Box className="BodyContent">
                <Typography variant="h3" style={{ marginTop: "24px" }}>
                  Emulation Report
                </Typography>
                <Typography variant="h6" style={{ marginTop: "24px" }}>
                  {WarningText[ReportData.FoundMissData]}
                </Typography>
                <Box style={{ width: "100%", textAlign: "right" }}>
                  <ActionButton
                    onClick={ExportPDFWarnings}
                    style={{ marginTop: "24px" }}
                  >
                    Download Pdf
                  </ActionButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
      {ReportData.CheckData && ReportData.FoundMissData <= 0 ? (
        <Box
          style={{
            border: "6px solid #090f1a",
            borderTop: "0px",
            paddingLeft: "7px",
            paddingRight: "7px",
            
          }}
        >
          <Box style={{ width: "100%" }}>
            <Grid container spacing={0} >
              <Grid item md={4}>
                <IconButton
                  onClick={() => {
                    props.Back();
                  }}
                  style={{ display: "inline-block" }}
                >
                  <img src={ArrowIcon} alt="" />
                </IconButton>
                <Typography
                  style={{
                    display: "inline-block",
                    fontSize: "24px",
                    color: "#ffffff",
                    fontWeight: "600",
                  }}
                >
                  Emulation Report
                </Typography>
              </Grid>
              <Grid item md={3}>
                <ReportModeSwitch
                  sx={{ m: 1 }}
                  onChange={() => {
                    settypeStatus(!typeStatus);
                    GenerateReportXlXS(!typeStatus, ReportData);
                  }}
                  value={typeStatus}
                />
                <Typography
                  className={typeStatus ? "TitleAttack" : "TitleDefend"}
                >
                  {typeStatus ? "Attacker Mode" : "Defender Mode"}
                </Typography>
              </Grid>
              <Grid item md={5} align="right">
                <ModalWizard
                  idExecution={ReportData.ReportData.idExecution}
                  DataUser={DataUser}
                  DateSelect={dateselect}
                  importData={exportData}
                  ReportData={ReportData}
                  fileName={ReportData.ReportData.name}
                  ExportPDF={ExportPDF}
                  ExportPDFDetails={ExportPDFDetails}
                />
              </Grid>
            </Grid>
            <Tooltip title={ReportData.ReportData.name}>
              <Typography
                variant="h6"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "350px",
                  whiteSpace: "nowrap",
                  color: "white",
                  display: "inline-block",
                  marginLeft: "15px",
                }}
              >
                {"Emulation Name: " + ReportData.ReportData.name}
              </Typography>
            </Tooltip>
          </Box>
          {!ReportData.ReportData ? (
            <Box className="DashboardReport">
              <LinearProgress color="secondary" />
            </Box>
          ) : (
            <>
              <ExecutionDashboard
                typeStatus={typeStatus}
                countExecutionsLength={ReportData.ExecutionTable.length}
                countSendArtifacts={ReportData.CountSendArtifacts}
                clientsCount={ReportData.clientsCount}
                indexExecution={index}
                succesResultExecution={ReportData.SuccesResultExecution}
                failedResultExecution={ReportData.FailedResultExecution}
                succesResultArtifacts={ReportData.SuccesResultArtifacts}
                failedResultArtifacts={ReportData.FailedResultArtifacts}
                tabledata1={ReportData.tabledata1}
              />
              <Box
                className={
                  typeStatus
                    ? "DashboardReport Attack"
                    : "DashboardReport Defend"
                }
                style={{ backgroundColor: "#090f1a" }}
              >
                <Box
                  style={{
                    paddingTop: ".5rem !important",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  <Button
                    className="HandleButtons"
                    style={{
                      color: "#fff",
                      borderRadius: "12px 0px 0px 12px",
                      backgroundColor: TableBy ? "#c59d48" : "#0a0818",
                      borderColor: TableBy ? "#c59d48" : "#1e1c3a",
                    }}
                    onClick={() => {
                      setIndex(0);
                      setTableBy(!TableBy);
                    }}
                  >
                    Emulation Results
                  </Button>
                  <Button
                    className="HandleButtons"
                    style={{
                      color: "#fff",
                      borderRadius: "0px 12px 12px 0px ",
                      backgroundColor: !TableBy ? "#c59d48" : "#0a0818",
                      borderColor: !TableBy ? "#c59d48" : "#1e1c3a",
                    }}
                    onClick={() => {
                      setIndex(1);
                      setTableBy(!TableBy);
                    }}
                  >
                    File Level Results
                  </Button>
                  {TableBy ? (
                    <Table>
                      <TableHead
                        style={{
                          backgroundColor: "#0d0b21",
                          borderRadius: "12px",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ borderBottom: "1px solid #1e1c3a" }}
                          >
                            <Typography style={{ color: "#fff" }}>
                              Host Name
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ borderBottom: "1px solid #1e1c3a" }}
                          >
                            <Typography style={{ color: "#fff" }}>
                              Advanced Results
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ borderBottom: "1px solid #1e1c3a" }}
                          >
                            <Typography style={{ color: "#fff" }}>
                              Created
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ borderBottom: "1px solid #1e1c3a" }}
                          >
                            <Typography style={{ color: "#fff" }}>
                              Updated
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ borderBottom: "1px solid #1e1c3a" }}
                          >
                            <Typography style={{ color: "#fff" }}>
                              Status
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ReportData.ExecutionTable.length > 0
                          ? ReportData.ExecutionTable.map((row, index) => (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  style={{
                                    color: "#66638f",
                                    borderBottom: "1px solid #1e1c3a",
                                  }}
                                >
                                  {row.client}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    color: "#66638f",
                                    borderBottom: "1px solid #1e1c3a",
                                  }}
                                >
                                  <ModalGeneralReport AgentGlobalData={row} />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    color: "#66638f",
                                    borderBottom: "1px solid #1e1c3a",
                                  }}
                                >
                                  {row.StartDate}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    color: "#66638f",
                                    borderBottom: "1px solid #1e1c3a",
                                  }}
                                >
                                  {row.FinishDate}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{
                                    color: "#66638f",
                                    borderBottom: "1px solid #1e1c3a",
                                  }}
                                >
                                  {row.Qualification === "failed" ? (
                                    <>
                                      {typeStatus ? (
                                        <Box
                                          className="ExecStatus"
                                          style={{
                                            color: "#ff4747",
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={FailIcon}
                                            style={{ marginRight: "7px" }}
                                          />
                                          Fail
                                        </Box>
                                      ) : (
                                        <Box
                                          className="ExecStatus"
                                          style={{
                                            color: "#38e25d",
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={SucessIcon}
                                            style={{ marginRight: "7px" }}
                                          />
                                          Success
                                        </Box>
                                      )}
                                    </>
                                  ) : row.Qualification ===
                                    "partiallySatisfactory" ? (
                                    <Box
                                      className="ExecStatus"
                                      style={{
                                        color: "orange",
                                      }}
                                    >
                                      <AssignmentLateIcon />
                                      Partially Satisfactory
                                    </Box>
                                  ) : (
                                    <>
                                      {typeStatus ? (
                                        <Box
                                          className="ExecStatus"
                                          style={{
                                            color: "#38e25d",
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={SucessIcon}
                                            style={{ marginRight: "7px" }}
                                          />
                                          Success
                                        </Box>
                                      ) : (
                                        <Box
                                          className="ExecStatus"
                                          style={{
                                            color: "#ff4747",
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={FailIcon}
                                            style={{ marginRight: "7px" }}
                                          />
                                          Fail
                                        </Box>
                                      )}
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  ) : null}
                  {!TableBy ? (
                    <TableReportToAgentsAndArtifacts
                      typeStatus={typeStatus}
                      DataUser={DataUser}
                      tabledata1={ReportData.tabledata1}
                    />
                  ) : null}
                </Box>
              </Box>

              <Box
                style={{
                  position: "relative",
                  width: " 100%",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  display: Selected ? "block" : "none",
                }}
              >
                <Box
                  style={{
                    marginBottom: "1.5rem !important",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#090f1a",
                      border: "1px solid #1e1c3a",
                      padding: "15px 15px",
                      borderRadius: "12px",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#ffffff",
                    }}
                  >
                    Package Description
                  </Box>
                  {ReportData.PackagesGlobal.length > 0
                    ? ReportData.PackagesGlobal.map((element) => (
                        <Box>
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: "15px",
                              display: "block",
                              padding: "15px 0 5px 0",
                              color: "#ffffff",
                            }}
                          >
                            {element.Name}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "16px",
                              lineHeight: "30px",
                              color: "#66638f",
                              marginBottom: "0",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {element.Description}
                          </Typography>
                        </Box>
                      ))
                    : null}
                </Box>
                <Box
                  id="GraficTable"
                  style={{
                    visibility: "hidden",
                    fontSize: "18px",
                    height: "auto",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#090f1a",
                      border: "1px solid #1e1c3a",
                      padding: "15px 15px",
                      borderRadius: "12px",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#ffffff",
                    }}
                  >
                    List Artifacs Types
                  </Box>
                  <Box style={{ width: "100%", height: "10px" }}>
                    <Box id="DataListTypes"></Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    marginBottom: "1.5rem !important",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#090f1a",
                      border: "1px solid #1e1c3a",
                      padding: "15px 15px",
                      borderRadius: "12px",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#ffffff",
                    }}
                  >
                    MITRE Attack Applied
                  </Box>

                  <Box style={{ height: "100%" }}>
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        display: "block",
                        padding: "15px 0 5px 0",
                        color: "#ffffff",
                      }}
                    >
                      {ReportData.attackName}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "16px",
                        lineHeight: "30px",
                        color: "#66638f",
                        marginBottom: "0",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {ReportData.attackDescription}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      position: "relative",
                      width: " 100%",
                    }}
                  >
                    <Cyclelife
                      MtreLong={ReportData.MtreLong}
                      MitreArray={ReportData.MitreArray}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      ) : null}
    </>
  );
}
