import { Clear, Search, Star } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  TablePagination,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useState } from "react";
import { ConvertDateUtc } from "../../../../../../../ayudas/GlobalData";
import {
  CustomLoadingOverlay,
  FormInputTextField,
  pageTheme,
  PrimaryButton,
  SecondaryButton,
  StyledDataGrid,
} from "../../../../../../../ayudas/Themes";
import { GetPaginationAdvance } from "../../../../../Services/ServiceScriptPage";
import WindowIcon from "../../../../../../../assets/images/window-icon.svg";
import LinuxIcon from "../../../../../../../assets/images/linux-icon.svg";
import Multi from "../../../../../../../assets/multi.png";
import TablePaginationActions from "../../../../../../../ayudas/TablePaginations";
export default function StepSelectScript(props) {
  const { DataUser, valueSelectionScript } = props;
  const [sortModel, setSortModel] = useState([
    { field: "updatedAt", sort: "desc" },
  ]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tempRows, settempRows] = useState([]);
  const [rowCount, setrowCount] = useState(0);
  const [selectionModel, setSelectionModel] = useState(valueSelectionScript);
  const [FilterName, setFilterName] = useState("");

  const getScriptsListPost = useCallback(
    async (size, Pagina, bodyContent) => {
      setLoading(true);
      let objFormData = {};
      if (bodyContent) {
        if (bodyContent?.name !== null) {
          objFormData.name = bodyContent.name;
        }
        if (bodyContent?.tagTest !== null) {
          objFormData.tagTest = bodyContent.tagTest;
        }
        if (bodyContent?.platform !== null) {
          objFormData.platform = bodyContent.platform;
        }

        if (bodyContent?.language !== null) {
          objFormData.language = bodyContent.language;
        }
      }
      const response = await GetPaginationAdvance(
        DataUser.token,
        size,
        Pagina + 1,
        sortModel.length > 0 ? sortModel[0].field : "updatedAt",
        sortModel.length > 0 ? sortModel[0].sort : "desc",
        objFormData
      );
      if (response) {
        let array = [];
        response.scripts.forEach((element) => {
          array.push({
            id: element._id,
            platform: element.platform,
            language: element.language,
            source: element.source,
            name: element.name,
            tagTest: element.tagTest,
            updatedAt: ConvertDateUtc(element.updatedAt),
            actions: element.file,
          });
        });
        settempRows(array);
        setrowCount(response.info.neto);
        setLoading(false);
      } else {
        settempRows([]);
        setrowCount(0);
        setLoading(false);
      }
    },
    [DataUser.token, sortModel]
  );

  const getScriptsList = useCallback(async () => {
    setLoading(true);
    const response = await GetPaginationAdvance(
      DataUser.token,
      rowsPerPage,
      page + 1,
      sortModel.length > 0 ? sortModel[0].field : "updatedAt",
      sortModel.length > 0 ? sortModel[0].sort : "desc"
    );
    if (response) {
      let array = [];
      response.scripts.forEach((element) => {
        array.push({
          id: element._id,
          platform: element.platform,
          language: element.language,
          name: element.name,
          tagTest: element.tagTest,
          updatedAt: ConvertDateUtc(element.updatedAt),
          actions: element.file,
          source: element.source,
        });
      });
      settempRows(array);
      setrowCount(response.info.neto);
      setLoading(false);
    } else {
      settempRows([]);
      setrowCount(0);
      setLoading(false);
    }
  }, [DataUser.token, page, rowsPerPage, sortModel]);

  const Checkfilters = useCallback(() => {
    let flag = false;
    if (FilterName !== "") {
      flag = true;
    }
    return flag;
  }, [FilterName]);

  const ConstructorBody = useCallback(() => {
    return {
      name: FilterName ? FilterName : null,
    };
  }, [FilterName]);

  useEffect(() => {
    setSelectionModel(valueSelectionScript);
  }, [valueSelectionScript]);

  useEffect(() => {
    let active = true;
    (async () => {
      if (Checkfilters()) {
        await getScriptsListPost(rowsPerPage, page, ConstructorBody());
      } else {
        await getScriptsList(rowsPerPage, page);
      }

      if (!active) {
        return;
      }
    })();

    return () => {
      active = false;
    };
  }, [
    rowsPerPage,
    page,
    sortModel,
    Checkfilters,
    ConstructorBody,
    getScriptsList,
    getScriptsListPost,
  ]);

  useEffect(() => {
    let delayDebounceFn;
    if (Checkfilters()) {
      delayDebounceFn = setTimeout(() => {
        getScriptsListPost(rowsPerPage, 0, ConstructorBody());
      }, 350);
    } else {
      delayDebounceFn = setTimeout(() => {
        getScriptsListPost(rowsPerPage, 0);
      }, 350);
    }
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [
    FilterName,
    Checkfilters,
    ConstructorBody,
    getScriptsListPost,
    rowsPerPage,
  ]);

  const HandleFilterName = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== "") {
        setFilterName(e.target.value);
      } else {
        setFilterName("");
      }
    }
  };
  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  const handleToggleScripts = (value) => () => {
    const currentIndex = findWithAttr(selectionModel, "id", value.id);
    const newChecked = [...selectionModel];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectionModel(newChecked);
  };

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  const Columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
      filterable: false,
      sortable: false,
      minWidth: 40,
      align: "center",
      renderHeader: () => <Box style={{ color: "#0a0818" }}>|</Box>,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Checkbox
          style={{ padding: 10 }}
          onClick={handleToggleScripts({
            id: params.row.id,
            name: params.row.name,
            language: params.row.language,
            platform: params.row.platform,
            source: params.row.source,
            tagTest: params.row.tagTest,
            updatedAt: params.row.updatedAt,
          })}
          checked={findWithAttr(selectionModel, "id", params.value) !== -1}
        ></Checkbox>
      ),
    },
    {
      field: "platform",
      headerName: "Platform",
      flex: 0.5,
      filterable: false,
      minWidth: 100,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Platform</Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            alignContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {params.row.platform === "Windows" ? (
            <img
              src={WindowIcon}
              alt=""
              style={{ marginRight: "7px", width: "30px", height: "30px" }}
            />
          ) : params.row.platform === "GNULinux" ? (
            <img
              src={LinuxIcon}
              alt=""
              style={{ marginRight: "7px", width: "30px", height: "30px" }}
            />
          ) : (
            <>
              <img
                src={Multi}
                alt=""
                style={{ width: "30px", height: "30px" }}
              />
            </>
          )}
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Box
          style={{
            alignContent: "center",
            height: "100%",
            whiteSpace: "nowrap",
            display: "flex",
            textOverflow: "ellipsis",
            justifyContent: "start",
            flexWrap: "wrap",
          }}
        >
          {params.row.source === "FILERAPTOR" ? (
            <Tooltip title={"E.V.E."}>
              <Star
                color="warning"
                style={{ fontSize: "12px", marginRight: "7px" }}
              />
            </Tooltip>
          ) : null}
          <Typography>{params.row.name}</Typography>
        </Box>
      ),
    },

    {
      field: "language",
      headerName: "Language",
      flex: 0.5,
      filterable: false,
      minWidth: 100,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Language</Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.language === "BATHWIN"
            ? "bash"
            : params.row.language === "BATHLinux"
            ? "shell"
            : params.row.language === "phython"
            ? "python"
            : params.row.language}
        </>
      ),
    },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handlepaginationMode = (event) => {
    if (
      event.pageSize === 5 ||
      event.pageSize === 10 ||
      event.pageSize === 20
    ) {
      setRowsPerPage(parseInt(event?.pageSize, 10));
      setPage(parseInt(event?.page));
    }
  };
  return (
    <>
      <Box
        sx={{
          border: "1px solid rgb(30, 28, 58)",
          backgroundColor: "rgb(21, 19, 43)",
          padding: "20px",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        {/* <Typography
          color="secondary"
          style={{
            fontWeight: "600",
            display: "inline-block",
            float: "right",
          }}
        >
          Select Script:
          {selectionModelRow.map((row, index) => (
            <>
              <Typography
                color="secondary"
                style={{
                  fontWeight: "600",
                  display: "inline-block",
                  float: "right",
                }}
              >
                {" "}{index+1}.- {row.name}
              </Typography>
            </>
          ))}
        </Typography> */}
        <Typography textAlign={"center"}>Select Scripts:</Typography>
        <FormInputTextField
          onChange={(e) => {
            HandleFilterName(e);
          }}
          color="secondary"
          variant="outlined"
          value={FilterName}
          label="Search name..."
          InputProps={{
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                onClick={() => setFilterName("")}
              >
                {FilterName ? <Clear /> : <Search />}
              </IconButton>
            ),
          }}
          style={{ color: "#ffffff", marginBottom: "10px" }}
        />
        <Box style={{ width: "100%", maxHeight: "550px", overflow: "auto" }}>
          <ThemeProvider theme={pageTheme}>
            <StyledDataGrid
              slotProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                  showFirstButton: true,
                  showLastButton: true,
                },
              }}
              slots={{
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                NoResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
                Pagination: () => (
                  <>
                    <TablePagination
                      component="div"
                      rowCount={rowCount}
                      pageSizeOptions={[5, 10, 20]}
                    />
                  </>
                ),
              }}
              showLastButton
              showFirstButton
              rows={tempRows}
              columns={Columns}
              pagination={!loading}
              pageSizeOptions={[5, 10, 20]}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(newSort) => handleSortModelChange(newSort)}
              loading={loading}
              rowCount={rowCount}
              autoPageSize
              disableColumnMenu={true}
              disableSelectionOnClick
              disableRowSelectionOnClick
              disableColumnSelector
              suppressPaginationPanel={true}
              disableDensitySelector
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              onPaginationModelChange={(event) => {
                handlepaginationMode(event);
              }}
            />
          </ThemeProvider>
        </Box>
      </Box>

      <SecondaryButton
        color="inherit"
        // disabled={activeStep === 0}
        onClick={() => {
          props.HandleScriptSelection(selectionModel);
          props.HandleActiveStepBack();
        }}
        sx={{ mr: 1 }}
      >
        Back
      </SecondaryButton>
      <PrimaryButton
        disabled={selectionModel.length > 0 ? false : true}
        style={{ float: "right" }}
        onClick={() => {
          props.HandleScriptSelection(selectionModel);
          props.HandleActiveStepNext();
        }}
      >
        Next
      </PrimaryButton>
    </>
  );
}
