import { useState, useEffect, useMemo, useCallback } from "react";
import Cookies from "universal-cookie";

import {
  Box,
  Button,
  ThemeProvider,
  IconButton,
  Menu,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Chip,
  TablePagination,
} from "@mui/material";
import PopOverFilter from "../PopOverFilter/PopOverFilter";

import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import recordsIcon from "../../../assets/images/records-icon.svg";
import detailsIcon from "../../../assets/images/eye-icon.svg";
import PopUpStartExecution from "./PopUpStartExecution/PopUpStartExecution";
import PopUpModalDetailsExecution from "./PopUpDetailsExecution/PopUpDetailsExecution";
import PopUpDeleteExecution from "./PopUpDeleteExecution/PopUpDeleteExecution";
import {
  Clear,
  Search,
  FilterAlt,
  StopCircle,
  SportsScore,
  Delete,
} from "@mui/icons-material";
import {
  ButtonActions,
  pageTheme,
  StyledDataGrid,
  CustomLoadingOverlay,
  FormInputTextField,
} from "../../../ayudas/Themes";
import { GetDate, rolValidation } from "../../../ayudas/ScriptsFunctions";
import launchIcon from "../../../assets/images/launch-icon.svg";
import refreshIcon from "../../../assets/images/reset-icon.svg";
import { getReportByExecutionIdAsync } from "../../../Services/Reports";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { GridOverlay } from "@mui/x-data-grid-pro";
import axios from "axios";
import AxiosFetch from "../../../Services/FetchServices";
import { env2 } from "../../../ayudas/Instance";
import { endPoint } from "../../../Services/EndPoint";
import { enqueueSnackbar } from "notistack";
import TablePaginationActions from "../../../ayudas/TablePaginations";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ButtonRecords = styled(Button)({
  padding: "10px",
  color: "#ffab2d",
  backgroundColor: "#382a2b",
  "&:hover": {
    backgroundColor: "#362a2bc4",
  },
});

const ButtonLauncher = styled(Button)({
  padding: "10px",
  color: "#fff",
  backgroundColor: "#b69141",
});

const ButtonRows = styled(Button)(({ theme }) => ({
  margin: "5px",
  marginTop: "10px",
  borderRadius: "8px",
  color: theme.palette.getContrastText("#544628"),
  backgroundColor: "#2e343d",
  border: "1px solid gray",
  "&:hover": {
    backgroundColor: "#544628",
  },
}));

export default function ExecutionsOnDemand(props) {
  const {
    DataUser,
    HandleDataDate,
    tagExecute,
    AgentConnect,
    refrestTable,
    SelectedPage,
  } = props;
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array
  const [page, setPage] = useState(
    cookies.get("DataPagination")?.page
      ? cookies.get("DataPagination")?.page
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    cookies.get("DataPagination")?.rowsPerPage
      ? cookies.get("DataPagination")?.rowsPerPage
      : 10
  );
  const [NoExecutions, setNoExecutions] = useState(0);
  const [tempRows, settempRows] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalDetailsStartExecution, setOpenModalStartExecution] =
    useState(false);
  const [idOfExecutionDataRow, setIdOfExecutionDataRow] = useState();
  const [ExecutionDataRow, setExecutionDataRow] = useState();
  const [ExecutionDataRowStart, setExecutionDataRowStart] = useState();
  const [FilterName, setFilterName] = useState(
    cookies.get("DataFiltersPagination")?.FilterName
      ? cookies.get("DataFiltersPagination")?.FilterName
      : ""
  );
  const [selectionModel, setSelectionModel] = useState([]);
  const [valueDate, setValueDate] = useState(
    cookies.get("DataFiltersPagination")?.valueDate
      ? cookies.get("DataFiltersPagination")?.valueDate
      : HandleDataDate
  );
  const [sortModel, setSortModel] = useState([
    { field: "lastModification", sort: "desc" },
  ]);
  const [showEl, setShowel] = useState(null);

  const [FilterProcesState, setFilterProcesState] = useState(
    cookies.get("DataFiltersPagination")?.FilterProcesState
      ? cookies.get("DataFiltersPagination")?.FilterProcesState
      : ""
  );
  const [FilterVector, setFilterVector] = useState(
    cookies.get("DataFiltersPagination")?.FilterVector
      ? cookies.get("DataFiltersPagination")?.FilterVector
      : ""
  );
  const [FilterStatus, setFilterStatus] = useState(
    cookies.get("DataFiltersPagination")?.FilterStatus
      ? cookies.get("DataFiltersPagination")?.FilterStatus
      : ""
  );
  const [signalReload, setSignalReload] = useState(0);
  const [disabledPagination, setdisabledPagination] = useState(false);
  const [isloading, setloading] = useState(false);

  // const [paginationModel, setPaginationModel] = useState({
  //   pageSize: rowsPerPage,
  //   page: page,
  // });

  const VectorList = [
    {
      id: 1,
      name: "Network",
      value: "NetworkSecurity",
    },
    {
      id: 2,
      name: "Endpoint",
      value: "EPP",
    },
    {
      id: 3,
      name: "Execution",
      value: "RealExecution",
    },
  ];

  const ProcStateList = [
    {
      id: 1,
      name: "Finished",
    },
    {
      id: 2,
      name: "Off",
    },
  ];

  const StatusList = [
    {
      id: 1,
      label: "Not Executed",
    },
    {
      id: 2,
      label: "Not Successful",
    },
    {
      id: 3,
      label: "Successful",
    },
  ];

  const StatusListValues = useMemo(
    () => [
      {
        id: 1,
        name: ["new"],
        label: "Not Executed",
      },
      {
        id: 2,
        name: ["noSend", "partiallySatisfactory", "failed"],
        label: "Not Successful",
      },
      {
        id: 3,
        name: ["satisfactory"],
        label: "Successful",
      },
    ],
    []
  );

  const abrir = Boolean(showEl);
  const handleClick = (event) => {
    setShowel(event.currentTarget);
  };
  const handleClose = () => {
    setShowel(null);
  };

  const ConvertDateNew = (myDate) => {
    var dt = new Date(myDate);

    var year = dt.getFullYear();
    var month = (dt.getMonth() + 1).toString().padStart(2, "0");
    var day = dt.getDate().toString().padStart(2, "0");
    var dateformat = year + "-" + month + "-" + day;
    return dateformat;
  };

  const ConstructorBody = useCallback(() => {
    let array = {};
    if (FilterName) {
      array.name = FilterName;
    }
    if (FilterVector !== "") {
      array.vector = FilterVector;
    }
    if (FilterProcesState !== "") {
      array.status = FilterProcesState;
    }
    if (valueDate[0] && valueDate[1]) {
      array.lastModification = [
        ConvertDateNew(valueDate[0]),
        ConvertDateNew(valueDate[1]),
      ];

      let day = new Date();
      let GMT = day.toString().split("GMT")[1].split(" ")[0];
      let Hours = parseInt(GMT.charAt(0) + GMT.charAt(1) + GMT.charAt(2), 10);

      array.utc = Hours;
    }
    if (FilterStatus !== "") {
      if (FilterStatus === StatusListValues[0].label) {
        array.condition = StatusListValues[0].name;
      } else if (FilterStatus === StatusListValues[1].label) {
        array.condition = StatusListValues[1].name;
      } else {
        array.condition = StatusListValues[2].name;
      }
    }

    return JSON.stringify(array);
  }, [
    FilterName,
    FilterVector,
    FilterProcesState,
    valueDate,
    FilterStatus,
    StatusListValues,
  ]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cookies.set("CurrentPageDataPagination", "6", {
      path: "/",
      SameSite: "none",
      secure: true,
    });
    async function GetData() {
      const baseUrl = `${env2.api}${endPoint.getAdvancedExecutionPage}`;
      const paginationParams = `${rowsPerPage <= 0 ? 10 : rowsPerPage}/${
        page <= 0 ? 1 : page + 1
      }`;
      const sortParams =
        sortModel.length > 0
          ? `col=${sortModel[0].field}&order=${sortModel[0].sort}`
          : `col=lastModification&order=desc`;

      const url = `${baseUrl}${paginationParams}?${sortParams}`;
      setloading(true);

      const data = await AxiosFetch("POST", url, source, ConstructorBody());
      setloading(false);

      const response = data?.msg ? data.msg : null;
      if (
        response?.result &&
        response.query &&
        Array.isArray(response.result.executions)
      ) {
        let array = [];
        response.result.executions.forEach((element) => {
          array.push(element);
        });
        let NoTotal = response.result.info.neto;
        setNoExecutions(NoTotal);
        settempRows(array);
      } else {
        settempRows([]);
        setNoExecutions(0);
        setPage(0);
      }
    }
    GetData();
    setdisabledPagination(false);
    return () => {
      if (SelectedPage !== 6) source.cancel();
    };
  }, [
    tagExecute,
    SelectedPage,
    sortModel,
    FilterName,
    FilterProcesState,
    FilterVector,
    valueDate,
    FilterStatus,
    cookies,
    page,
    rowsPerPage,
    refrestTable,
    ConstructorBody,
    signalReload,
  ]);

  const CleanFilter = () => {
    const obj = {
      FilterName: "",
      FilterProcesState: "",
      FilterVector: "",
      FilterStatus: "",
      valueDate: [null, null],
    };
    cookies.set("DataFiltersPagination", JSON.stringify(obj), {
      path: "/",
      SameSite: "none",
      secure: true,
    });
    const defaultDataPAgination = { page: 0, rowsPerPage: 10 };
    cookies.set("DataPagination", JSON.stringify(defaultDataPAgination), {
      path: "/",
    });
    setFilterName("");
    cookies.set("DataFiltersPagination", JSON.stringify(""));
    setFilterProcesState("");
    setFilterVector("");
    setFilterStatus("");
    setValueDate([null, null]);
  };

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  useEffect(() => {
    setOpenModalDelete(openModalDelete);
  }, [openModalDelete]);

  const effectModalDelete = (value) => {
    setOpenModalDelete(value);
  };
  const effectModalDetails = (value) => {
    setOpenModalDetails(value);
  };
  const effectModalStartExecution = (value) => {
    setOpenModalStartExecution(value);
  };

  const HandleFilterName = (e) => {
    if (e.target.value !== " ") {
      const obj = {
        FilterName: e.target.value,
        FilterProcesState,
        FilterVector,
        FilterStatus,
        valueDate,
      };
      cookies.set("DataFiltersPagination", JSON.stringify(obj), {
        path: "/",
        SameSite: "none",
        secure: true,
      });
      setFilterName(e.target.value);
      cookies.set("DataFiltersPagination", JSON.stringify(e.target.value));

      setPage(0);
    } else {
      setFilterName("");
      cookies.set("DataFiltersPagination", JSON.stringify(""));

      setPage(0);
    }
  };

  const HandleFilterDate = (value) => {
    const obj = {
      FilterName,
      FilterProcesState,
      FilterVector,
      FilterStatus,
      valueDate: value,
    };
    cookies.set("DataFiltersPagination", JSON.stringify(obj), {
      path: "/",
      SameSite: "none",
      secure: true,
    });

    setValueDate(value);
    setPage(0);
  };

  const handleVector = (event) => {
    const {
      target: { value },
    } = event;

    setFilterVector(value);
    const obj = {
      FilterName,
      FilterProcesState,
      FilterVector: value,
      FilterStatus,
      valueDate,
    };
    setPage(0);

    cookies.set("DataFiltersPagination", JSON.stringify(obj), {
      path: "/",
      SameSite: "none",
      secure: true,
    });
  };

  const handleStatus = (event) => {
    const {
      target: { value },
    } = event;

    setFilterProcesState(value);
    setPage(0);

    const obj = {
      FilterName,
      FilterProcesState: value,
      FilterVector,
      FilterStatus,
      valueDate,
    };
    cookies.set("DataFiltersPagination", JSON.stringify(obj), {
      path: "/",
      SameSite: "none",
      secure: true,
    });
  };

  const handleStatusCondition = (event) => {
    const {
      target: { value },
    } = event;

    setFilterStatus(value);
    setPage(0);

    const obj = {
      FilterName,
      FilterProcesState,
      FilterVector,
      FilterStatus: value,
      valueDate,
    };
    cookies.set("DataFiltersPagination", JSON.stringify(obj), {
      path: "/",
      SameSite: "none",
      secure: true,
    });
  };

  const newReportData = async (ID) => {
    let day = new Date();
    let GMT = day.toString().split("GMT")[1].split(" ")[0];
    let value = parseInt(GMT.charAt(0) + GMT.charAt(1) + GMT.charAt(2), 10);

    let body = {
      utc: value,
    };
    const response = await getReportByExecutionIdAsync(
      ID,
      JSON.stringify(body)
    );
    if (response.query) {
      response.id = ID;
      props.Report(response);
    } else {
      enqueueSnackbar(response.result, { variant: "info" });
    }
  };

  const effectRefresh = () => {
    setSignalReload(signalReload + 1);
  };

  const Execute = (value) => {
    props.Execute(value);
  };

  //Construccion de DataGrid

  const Columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
      align: "left",
    },

    {
      field: "lastModification",
      headerName: `Date Uploaded`,
      flex: 0.8,
      minWidth: 100,
      filterable: false,
      align: "left",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          {`Date Uploaded`}
        </Box>
      ),
      renderCell: (params) => <>{GetDate(params.value)}</>,
    },
    {
      field: "vector",
      headerName: "Vector",
      flex: 0.7,
      minWidth: 140,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Vector</Box>
      ),
      renderCell: (params) => (
        <>
          {params.value === "RealExecution" ? (
            <Chip
              style={{ width: "200px" }}
              variant="outlined"
              label="Execution"
              color="success"
            />
          ) : params.value === "EPP" ? (
            <Chip
              style={{ width: "200px" }}
              variant="outlined"
              label="Endpoint"
              color="warning"
            />
          ) : (
            <Chip
              style={{ width: "200px" }}
              variant="outlined"
              label="Network"
              color="secondary"
            />
          )}
        </>
      ),
    },
    {
      field: "condition",
      headerName: "Status",
      flex: 0.6,
      filterable: false,
      minWidth: 100,
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>{"Status"}</Box>
      ),
      renderCell: (params) => (
        <>
          {params.value === "new" ? (
            <Chip
              style={{ width: "200px" }}
              variant="outlined"
              label="Not Executed"
              color="info"
            />
          ) : params.value === "noSend" ||
            params.value === "partiallySatisfactory" ||
            params.value === "failed" ? (
            <Chip
              style={{ width: "200px" }}
              variant="outlined"
              label="Not Successful"
              color="error"
            />
          ) : (
            <Chip
              style={{ width: "200px" }}
              variant="outlined"
              label="Successful"
              color="success"
            />
          )}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      minWidth: 100,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Process State</Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{ textAlign: "center", marginTop: "1.5vh", marginLeft: "1.5vw" }}
        >
          {params.value === "Finished" ? (
            <Box
              style={{ textAlign: "center", display: "flex" }}
              className="Tag Finished"
            >
              <SportsScore
                style={{ color: "green", verticalAlign: "middle" }}
                className="mr-2"
              />
              <Typography
                style={{
                  color: "green",
                  verticalAlign: "middle",
                  marginTop: "3px",
                }}
              >
                Finish
              </Typography>
            </Box>
          ) : null}
          {params.value === "Running" ? (
            <Box
              style={{ textAlign: "center", display: "flex" }}
              className="Tag Running"
            >
              <DirectionsRunIcon
                style={{ color: "orange", verticalAlign: "middle" }}
              />
              <Typography
                style={{
                  color: "orange",
                  fontSize: "13px",
                  marginTop: "3px",
                }}
              >
                Running
              </Typography>
            </Box>
          ) : null}
          {params.value === "Off" ? (
            <Box
              style={{ textAlign: "center", display: "flex" }}
              className="Tag Off"
            >
              <StopCircle
                style={{ color: "red", verticalAlign: "middle" }}
                className="mr-2"
              />
              <Typography
                style={{
                  color: "red",
                  verticalAlign: "middle",
                  fontSize: "13px",
                  marginTop: "3px",
                }}
              >
                Off
              </Typography>
            </Box>
          ) : null}
        </Box>
      ),
    },
    {
      field: "launcher",
      headerName: "Launcher",
      sortable: false,
      filterable: false,
      flex: 0.7,
      minWidth: 100,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Launcher</Box>
      ),
      renderCell: (params) => (
        <>
          {tagExecute?.Data.ExecutionID === params.id ? (
            <>
              {tagExecute?.Code === "SOCKET.ExeFinished" ? (
                <>
                  <Tooltip title="Launch Emulation" arrow>
                    <ButtonLauncher
                      onClick={() => {
                        setExecutionDataRowStart(params.row);
                        setOpenModalStartExecution(true);
                      }}
                    >
                      <img
                        style={{ marginRight: "8px" }}
                        src={launchIcon}
                        alt=""
                      />
                      Launch
                    </ButtonLauncher>
                  </Tooltip>
                </>
              ) : params.row.status === "Off" ? (
                <>
                  <Tooltip title="Launch Emulation" arrow>
                    <ButtonLauncher
                      onClick={() => {
                        setExecutionDataRowStart(params.row);
                        setOpenModalStartExecution(true);
                      }}
                    >
                      <img
                        style={{ marginRight: "8px" }}
                        src={launchIcon}
                        alt=""
                      />
                      Launch
                    </ButtonLauncher>
                  </Tooltip>
                </>
              ) : params.row.status === "Finished" ? (
                <>
                  <Tooltip title="Launch Emulation" arrow>
                    <ButtonLauncher
                      onClick={() => {
                        setExecutionDataRowStart(params.row);
                        setOpenModalStartExecution(true);
                      }}
                    >
                      <img
                        style={{ marginRight: "8px" }}
                        src={launchIcon}
                        alt=""
                      />
                      Launch
                    </ButtonLauncher>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Launch Emulation" arrow>
                    <ButtonLauncher disabled={true}>
                      <img
                        style={{ marginRight: "8px" }}
                        src={launchIcon}
                        alt=""
                      />
                      Launch
                    </ButtonLauncher>
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              {params.row.status === "Finished" ? (
                <Box className="Tag Finished">
                  <Tooltip title="Launch Emulation" arrow>
                    <ButtonLauncher
                      onClick={() => {
                        setExecutionDataRowStart(params.row);
                        setOpenModalStartExecution(true);
                      }}
                    >
                      <img
                        style={{ marginRight: "8px" }}
                        src={launchIcon}
                        alt=""
                      />
                      Launch
                    </ButtonLauncher>
                  </Tooltip>
                </Box>
              ) : params.row.status === "Off" ? (
                <Box className="Tag Off">
                  <Tooltip title="Launch Emulation" arrow>
                    <ButtonLauncher
                      disabled={rolValidation()}
                      onClick={() => {
                        setExecutionDataRowStart(params.row);
                        setOpenModalStartExecution(true);
                      }}
                    >
                      <img
                        style={{ marginRight: "8px" }}
                        src={launchIcon}
                        alt=""
                      />
                      Launch
                    </ButtonLauncher>
                  </Tooltip>
                </Box>
              ) : (
                <Box className="Tag Off">
                  <Tooltip title="Launch Emulation" arrow>
                    <ButtonLauncher disabled={true}>
                      <img
                        style={{ marginRight: "8px" }}
                        src={launchIcon}
                        alt=""
                      />
                      Launch
                    </ButtonLauncher>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </>
      ),
    },
    {
      field: "reports",
      headerName: "Reports",
      flex: 0.7,
      minWidth: 100,
      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Reports</Box>
      ),
      renderCell: (params) => (
        <>
          {tagExecute?.Data.ExecutionID === params.id ? (
            <>
              {tagExecute?.Code === "SOCKET.ExeFinished" ? (
                <>
                  <ButtonRecords
                    onClick={() => {
                      newReportData(params.row.id);
                    }}
                  >
                    <img
                      style={{ marginRight: "8px" }}
                      src={recordsIcon}
                      className="mr-3"
                      alt=""
                    />
                    Report
                  </ButtonRecords>
                </>
              ) : params.row.status === "Off" ? (
                <>
                  <ButtonRecords
                    onClick={() => {
                      newReportData(params.row.id);
                    }}
                  >
                    <img
                      style={{ marginRight: "8px" }}
                      src={recordsIcon}
                      className="mr-3"
                      alt=""
                    />
                    Report
                  </ButtonRecords>
                </>
              ) : params.row.status === "Finished" ? (
                <>
                  <ButtonRecords
                    onClick={() => {
                      newReportData(params.row.id);
                    }}
                  >
                    <img
                      style={{ marginRight: "8px" }}
                      src={recordsIcon}
                      className="mr-3"
                      alt=""
                    />
                    Report
                  </ButtonRecords>
                </>
              ) : (
                <>
                  <ButtonRecords disabled={true}>
                    <img
                      style={{ marginRight: "8px" }}
                      src={recordsIcon}
                      className="mr-3"
                      alt=""
                    />
                    Report
                  </ButtonRecords>
                </>
              )}
            </>
          ) : (
            <>
              {params.row.status === "Finished" ? (
                <Box className="Tag Finished">
                  <ButtonRecords
                    onClick={() => {
                      newReportData(params.row.id);
                    }}
                  >
                    <img
                      style={{ marginRight: "8px" }}
                      src={recordsIcon}
                      className="mr-3"
                      alt=""
                    />
                    Report
                  </ButtonRecords>
                </Box>
              ) : params.row.status === "Off" ? (
                <Box className="Tag Off">
                  <ButtonRecords
                    onClick={() => {
                      newReportData(params.row.id);
                    }}
                  >
                    <img
                      style={{ marginRight: "8px" }}
                      src={recordsIcon}
                      className="mr-3"
                      alt=""
                    />
                    Report
                  </ButtonRecords>
                </Box>
              ) : (
                <Box className="Tag Off">
                  <ButtonRecords disabled={true}>
                    <img
                      style={{ marginRight: "8px" }}
                      src={recordsIcon}
                      className="mr-3"
                      alt=""
                    />
                    Report
                  </ButtonRecords>
                </Box>
              )}
            </>
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      filterable: false,
      align: "center",
      type: "actions",
      renderHeader: () => (
        <Box
          sx={{
            justifyContent: "center",
            display: "grid",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          Actions
        </Box>
      ),
      renderCell: (params) => (
        <>
          <Box style={{ display: "flex" }}>
            <Tooltip title="Details" arrow>
              <IconButton
                onClick={() => {
                  setExecutionDataRow(params.row);
                  setOpenModalDetails(true);
                }}
                className="iconButtonSize"
              >
                <img src={detailsIcon} alt="" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <IconButton
                disabled={rolValidation()}
                onClick={() => {
                  setIdOfExecutionDataRow(params.row.id);
                  setOpenModalDelete(true);
                }}
                className="iconButtonSize"
              >
                <Delete color="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ),
    },
  ];

  const handlepaginationMode = (event) => {
    if (
      event.pageSize === 5 ||
      event.pageSize === 10 ||
      event.pageSize === 20
    ) {
      setRowsPerPage(parseInt(event?.pageSize, 10));
      setPage(parseInt(event?.page));
      setdisabledPagination(true);
    }
  };

  return (
    <>
      <ThemeProvider theme={pageTheme}>
        <Box
          style={{
            width: "100%",
            backgroundColor: "#242933",
            border: " 1px solid #090f1a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "14px",
          }}
        >
          <div style={{ height: "auto", width: "100%" }}>
            <FormInputTextField
              onChange={(e) => {
                HandleFilterName(e);
              }}
              color="secondary"
              variant="outlined"
              value={FilterName}
              label="Search name..."
              InputProps={{
                endAdornment: (
                  <IconButton title="Clear" aria-label="Clear" size="small">
                    {FilterName ? (
                      <Clear
                        onClick={() => {
                          setFilterName("");
                          cookies.set(
                            "DataFiltersPagination",
                            JSON.stringify("")
                          );

                          setSignalReload(signalReload + 1);
                        }}
                      />
                    ) : (
                      <Search />
                    )}
                  </IconButton>
                ),
              }}
              style={{ marginBottom: "7px" }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: 1,
                  borderColor: "divider",
                },
              }}
            />
            <Tooltip title="Select Filter" arrow>
              <ButtonRows
                id="basic-button"
                aria-controls={abrir ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={abrir ? "true" : undefined}
                onClick={handleClick}
                style={{ marginLeft: "15px" }}
              >
                <FilterAlt style={{ color: "white" }} />
                <Typography
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  Filters
                </Typography>
              </ButtonRows>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={showEl}
              open={abrir}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <FormControl
                  sx={{ m: 1, width: 300 }}
                  style={{ marginTop: "-1px" }}
                >
                  <InputLabel
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    id="FilterTreat"
                    color="secondary"
                  >
                    Filter by process state
                  </InputLabel>
                  <Select
                    id="FilterPhase"
                    color="secondary"
                    variant="outlined"
                    value={FilterProcesState}
                    onChange={handleStatus}
                    MenuProps={MenuProps}
                  >
                    {ProcStateList.map((prcstatus) => (
                      <MenuItem
                        style={{
                          backgroundColor:
                            FilterProcesState === prcstatus.name
                              ? "purple"
                              : "transparent",
                        }}
                        key={prcstatus.id}
                        value={prcstatus.name}
                        onClick={() => {
                          if (FilterProcesState === prcstatus.name) {
                            setFilterProcesState("");
                          }
                        }}
                      >
                        {prcstatus.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MenuItem>
              <MenuItem>
                <FormControl
                  sx={{ m: 1, width: 300 }}
                  style={{ marginTop: "-1px" }}
                >
                  <InputLabel
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    id="FilterTreat"
                    color="secondary"
                  >
                    Filter by vector
                  </InputLabel>
                  <Select
                    color="secondary"
                    variant="outlined"
                    id="FilterTreat"
                    value={FilterVector}
                    onChange={handleVector}
                    MenuProps={MenuProps}
                  >
                    {VectorList.map((vector) => (
                      <MenuItem
                        style={{
                          backgroundColor:
                            FilterVector === vector.value
                              ? "purple"
                              : "transparent",
                        }}
                        key={vector.id}
                        value={vector.value}
                        onClick={() => {
                          if (FilterVector === vector.value) {
                            setFilterVector("");
                          }
                        }}
                      >
                        {vector.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MenuItem>
              <MenuItem>
                <FormControl
                  sx={{ m: 1, width: 300 }}
                  style={{ marginTop: "-1px" }}
                >
                  <InputLabel
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    id="FilterTreat"
                    color="secondary"
                  >
                    Filter by status
                  </InputLabel>
                  <Select
                    color="secondary"
                    variant="outlined"
                    value={FilterStatus}
                    onChange={handleStatusCondition}
                    MenuProps={MenuProps}
                  >
                    {StatusList.map((status) => (
                      <MenuItem
                        style={{
                          backgroundColor:
                            FilterStatus === status.label
                              ? "purple"
                              : "transparent",
                        }}
                        key={status.id}
                        value={status.label}
                        onClick={() => {
                          if (FilterStatus === status.label) {
                            setFilterStatus("");
                          }
                        }}
                      >
                        {status.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MenuItem>
              <MenuItem>
                <PopOverFilter
                  ValueDateGet={valueDate}
                  HandleFilterDate={HandleFilterDate}
                ></PopOverFilter>
              </MenuItem>
              <MenuItem>
                <Tooltip title="Clean Filters" arrow>
                  <ButtonActions
                    onClick={CleanFilter}
                    style={{ marginLeft: "30%" }}
                  >
                    Clear filters
                  </ButtonActions>
                </Tooltip>
              </MenuItem>
            </Menu>
            <Tooltip title="Refresh page table" arrow>
              <ButtonRows
                id="basic-button"
                aria-haspopup="true"
                onClick={() => {
                  setSignalReload(signalReload + 1);
                }}
              >
                <img style={{ marginRight: "10px" }} src={refreshIcon} alt="" />
                <Typography
                  style={{ color: "white", textTransform: "capitalize" }}
                >
                  Update
                </Typography>
              </ButtonRows>
            </Tooltip>
            <StyledDataGrid
              slotProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                  showFirstButton: true,
                  showLastButton: true,
                },
              }}
              components={{
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                NoResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
                Pagination: () => (
                  <>
                    <TablePagination
                      component="div"
                      rowCount={NoExecutions}
                      pageSizeOptions={[5, 10, 20]}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      // onPageChange={handleChangePage}
                      // onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                      disabled={disabledPagination}
                    />
                  </>
                ),
              }}
              //density="comfortable"
              pagination={!isloading}
              pageSizeOptions={[5, 10, 20]}
              rowCount={NoExecutions}
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(newSort) => handleSortModelChange(newSort)}
              disableColumnMenu={true}
              columns={Columns}
              rows={tempRows}
              loading={isloading}
              headerAlign="center"
              // autoHeight={true}
              autoPageSize
              disableColumnSelector
              disableSelectionOnClick
              suppressPaginationPanel={true}
              disableDensitySelector
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              onPaginationModelChange={(event) => {
                handlepaginationMode(event);
              }}
              showFirstButton
              showLastButton
            />
          </div>
        </Box>
      </ThemeProvider>
      <PopUpStartExecution
        Execute={Execute}
        DataUser={DataUser}
        ClientOfExecution={ExecutionDataRowStart}
        openModalGet={openModalDetailsStartExecution}
        effectModal={effectModalStartExecution}
        AgentConnect={AgentConnect}
      ></PopUpStartExecution>

      <PopUpModalDetailsExecution
        InfoExecution={ExecutionDataRow}
        GetOpenModal={openModalDetails}
        effectModal={effectModalDetails}
        DataUser={DataUser}
      ></PopUpModalDetailsExecution>
      <PopUpDeleteExecution
        idExecution={idOfExecutionDataRow}
        DataUser={DataUser}
        GetOpenModal={openModalDelete}
        effectModal={effectModalDelete}
        effectRefresh={effectRefresh}
      ></PopUpDeleteExecution>
    </>
  );
}
